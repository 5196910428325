import React from "react";
import Sidebar from "../../components/sidebar";
import Header from "../../components/Header";
import Footer from '../../components/Footer/Footer'
import RewardDesignPage from "../../components/RewardCardDesign/RewardDesignPage";

const RewardCardDesign = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <h1>Reward Card Design</h1>
        <RewardDesignPage />
        {/* <img src="https://static.wixstatic.com/media/5a50a9_a05fc160cd3949ff8d67451363202c7d~mv2.gif" width={390} style={{marginTop:"2rem"}}/> */}
        <Footer />
      </main>
    </>
  );
};

export default RewardCardDesign;
