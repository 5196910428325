import React, { useState } from "react";
import Header from "../components/Header";
import Main from "../components/Home/Main";
import Sidebar from "./../components/sidebar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";
import themeConfigs from "../configs/theme.configs";
import { Paper, Box } from "@mui/material";
import Footer from "../components/Footer/Footer"
const HomeScreen = () => {
  const [daskMode, setDarkMode] = useState(false);
  return (
    <ThemeProvider
      theme={themeConfigs.custom({ mode: daskMode ? "dark" : "light" })}
    >
      <Paper>
        <Sidebar />
        {/* <SidebarMui /> */}
        <main className="main-wrap">
          <Header  check={daskMode} change={()=> setDarkMode(!daskMode)}/>
          <Main />
          <Footer />
        </main>
        
      </Paper>
    </ThemeProvider>
  );
};

export default HomeScreen;
