export const heroOne = {
	id: 'projects',
	topLine: {
		text: 'First customers',
	},
	headline: 'Get your first customers in the door',
	description: 'A crowd attracts a crowd! A customer eating in your restaurant is an endorsement and social proof that your restaurant worth dining at. ',
	buttonLabel: 'View Detail',
	imgStart: 'start',
	img: './images/content/rocket.svg',
	backgroundColor: 'linear-gradient(	150deg,#c99fff -20%,#4a4eff)',
	start: 'true',
};

export const heroTwo = {
	reverse: true,
	topLine: {
		text: 'Free and Ease',
	},
	headline: 'It is free and easy! No commission, sign-up fees or minimum period',
	description:
		"Apart from offering an early bird special on food (drinks are always full price), there’s no cost or contracts for restaurants.",
	buttonLabel: 'View Detail',

	linkTo: '/more',
	imgStart: 'start',
	img: './images/content/landing-page.svg',
	backgroundColor: 'linear-gradient(	140deg	,#ffaf73 30%,#fffecc 120%)',

	start: 'true',
};

export const heroThree = {
	topLine: {
		text: 'Artificial Intelligent',
	},
	headline: "Advanced artificial intelligent find interested customers on time ",
	description:
		'Over 700,000 diners use Lokalai to try new and exciting restaurants each month. Our stats show 75% of subscribers are dining at our partner restaurants for the first time. Show them a wonderful time and they’ll tell their friends and return again.',
	buttonLabel: 'View Detail',

	linkTo: '/download',
	imgStart: '',
	backgroundColor: 'linear-gradient(104deg, rgba(151,147,218,1) 0%, rgba(183,137,205,1) 100%)',
	img: './images/content/lost.svg',
	start: 'true',
};

export const heroFour = {
	reverse: true,
	topLine: {
		text: 'Strengthen your online presence',
	},
	headline: 'New ECommerce systems Strengthen your online presence',
	description: 'Diners are encouraged to share their restaurant bookings on Facebook to increase visibility for your brand. In return, they earn free credit towards their next Lokalai booking fee',
	buttonLabel: 'View Detail',

	linkTo: '/more',
	imgStart: '',
	backgroundColor: 'linear-gradient(225deg,#0abac2,#b2de94)',
	img: './images/content/ecommerce.svg',
	start: 'true',
};

export const heroFive = {
	id: 'projects',
	topLine: {
		text: 'Digital Marketing',
	},
	headline: 'It’s free marketing',
	description: 'We give diners a great reason to try your restaurant for the first time. You benefit from free marketing to an engaged database of foodies through email, social media, paid media and influencer outreach.',
	buttonLabel: 'View Project',
	imgStart: 'start',
	img: './images/content/alien.svg',
	backgroundColor: 'linear-gradient(	150deg,#c99fff -20%,#4a4eff)',
	start: 'true',
};
