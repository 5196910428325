import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading4 from "../components/LoadingError/Loading4";
import Loading from "../components/LoadingError/Loading";

import Toast from "../components/LoadingError/Toast";
import { login } from "../Redux/Actions/userActions";
import { register } from "../Redux/Actions/userActions";
import Message from "../components/LoadingError/Error";
import Footer from "./IntroduceSubSections/Footer/Footer";
import backgroundSignin from "./Style/images/1.jpg";
import { toast } from "react-toastify";
import { GoogleAuth_URL } from "../Redux/Url";

const SignInScreen = ({ history }) => {
  window.scrollTo(0, 0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error,  userInfo, loading } = userLogin;
console.log({error})
  const [user, setUser] = useState(null);
  const [userLogin2, setUserLogin2] = useState(true)
  const [userRegister2, setUserRegister2] = useState(true)
  //google server
  

 useEffect(() => {
    const getUser = () => {
      fetch(`${GoogleAuth_URL}auth/login/success`, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("authentication has been failed!");
        })
        .then((resObject) => {
          console.log("resObject.user :",resObject.user)
          setUser(resObject.user);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUser();
  }, []);

  useEffect (()=>{
    if(user && userLogin2 ) {
      console.log({user})
      const name = user.displayName;
      const email= user._json.email;
      // console.log({email})
      const password = "googleprovider";
      const photo = user.photos[0].value;
      // console.log({photo})
      // dispatch(register(name, email, password, photo));
      dispatch(login(email, password));
      setUserLogin2(false)
    }
  
  
    if(error && userRegister2) {
      console.log("register new account :", user)
      const name = user.displayName;
      const email= user._json.email;
      // console.log({email})
      const password = "googleprovider";
      const photo = user.photos[0].value;
      // console.log({photo})
      dispatch(register(name, email, password, photo));
      setUserRegister2(false)
    }

      // const email= user._json.email;
      // const password = "googleprovider";
      // dispatch(login(email, password));
    
  })

  // end of google server

  useEffect(() => {
    if (userInfo) {
      history.push("/");
      toast.success("Sign in success");
    }
  }, [userInfo, history]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };


  const google = () => {
    window.open("http://localhost:7005/auth/google", "_self");
  };

  return (
    <>
      <div
        class="container d-flex justify-content-center align-items-center min-vh-100 min-vw-100"
        style={{
          backgroundImage: `url(${backgroundSignin})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        
        {loading && <Loading4 />}
        {!loading && 
        <div
          class="row rounded-5 p-3 shadow box-area"
          style={{
            background: "rgba(34, 219, 231,0.3",
          }}
        >
          <div class="col-md-6 left-box-signin">
            <div class="row align-items-center justify-content-center">
              <div class="header-text mb-3 justify-content-center text-center">
                <h2 style={{ color: "white", fontWeight: "800" }} class="mb-2">
                  OPERATOR LOG IN
                </h2>
                <p>Welcome back !</p>
              </div>
              {error && <Message variant="alert-danger">{error}</Message>}
              <form
                className="form-login3"
                onSubmit={submitHandler}
                style={{
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div class="input-group mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ paddingRight: "3px" }}
                  >
                    <path
                      fill="currentColor"
                      d="M11 11L3 6v10h10v2H3q-.825 0-1.413-.588T1 16V4q0-.825.588-1.413T3 2h16q.825 0 1.413.588T21 4v5h-2V6l-8 5Zm0-2l8-5H3l8 5Zm8 13q-1.65 0-2.825-1.175T15 18v-4.5q0-1.05.725-1.775T17.5 11q1.05 0 1.775.725T20 13.5V18h-2v-4.5q0-.2-.15-.35T17.5 13q-.2 0-.35.15t-.15.35V18q0 .825.588 1.413T19 20q.825 0 1.413-.588T21 18v-4h2v4q0 1.65-1.175 2.825T19 22ZM3 6V4v12V6Z"
                    />
                  </svg>
                  <input
                    placeholder="Email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    class="form-control form-control-lg bg-light fs-6 m-lg-2"
                    labelStyle="floating"
                  />
                </div>
                <div class="input-group mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="currentColor"
                      d="M21 2a8.998 8.998 0 0 0-8.612 11.612L2 24v6h6l10.388-10.388A9 9 0 1 0 21 2Zm0 16a7.013 7.013 0 0 1-2.032-.302l-1.147-.348l-.847.847l-3.181 3.181L12.414 20L11 21.414l1.379 1.379l-1.586 1.586L9.414 23L8 24.414l1.379 1.379L7.172 28H4v-3.172l9.802-9.802l.848-.847l-.348-1.147A7 7 0 1 1 21 18Z"
                    />
                    <circle cx="22" cy="10" r="2" fill="currentColor" />
                  </svg>
                  <input
                    class="form-control form-control-lg bg-light fs-6 m-lg-2"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i class="bx bx-lock-alt"></i>
                </div>

                <div class="input-group mb-3">
                  <button
                    class="btn btn-lg w-100 fs-6"
                    type="text"
                    style={{
                      background: "rgb(52, 118, 246)",
                      color: "#fff",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    Log in
                  </button>
                </div>

                <div class="input-group mb-2 d-flex justify-content-between">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="formCheck"
                    />
                    <label for="formCheck" class="form-check-label">
                      <small>Remember Me</small>
                    </label>
                  </div>
                  <div class="forgot">
                    <small>
                      <a href="#">Forgot Password?</a>
                    </small>
                  </div>
                </div>

              </form>
              <div class="input-group mb-3">
                  <button class="btn btn-lg btn-light w-100 fs-6" onClick={google}>
                    <img
                      src="images/google.png"
                      style={{ width: "20px" }}
                      class="me-2"
                    />
                    <small>Sign In with Google</small>
                  </button>
                </div>
            </div>
            <div class="row">
                  <small class="d-flex justify-content-between align-items-center mt-2">
                    Don't have an account?{" "}
                    <a
                      href="./signup"
                      style={{
                        fontWeight: 600,
                        marginLeft: "1rem",
                      }}
                    >
                      Sign Up
                    </a>
                  </small>
                </div>
          </div>
          <div
            class="col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column right-box-signin"
            style={{
              background: `linear-gradient(180deg, rgb(34, 219, 231) -0.9%, rgb(52, 118, 246) 88.7%)`,
            }}
          >
            <div class="featured-image mb-3">
              <img
                src="images/login.png"
                class="img-fluid"
                style={{ width: "350px" }}
              />
            </div>
            <img
              src="./images/lokalai5.png"
              style={{
                margin: "1rem",
                // border: "1px solid green",
                maxWidth: "100px",
              }}
            />

            <small
              class="text-white text-wrap text-center"
              style={{
                width: "17rem",
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
            >
              Unlock the Power of AI to superchage local businesses
            </small>
          </div>
        </div>
        }
      </div>

      <Footer />
    </>
  );
};

export default SignInScreen;
