const anchor = require("../../../assets/svg/anchor.svg");
const aromatherapy = require("../../../assets/svg/aromatherapy.svg");
const bagel2 = require("../../../assets/svg/bagel2.svg");
const ballet2 = require("../../../assets/svg/ballet2.svg");
const coffee2 = require("../../../assets/svg/coffee-2.svg");
const coffee = require("../../../assets/svg/coffee.svg");

const pictureStamps = [
  {
    value: anchor,
    label: "anchor",
  },
  {
    value: aromatherapy,
    label: "aromatherapy",
  },
  {
    value: bagel2,
    label: "bagel2",
  },
  {
    value: ballet2,
    label: "ballet2",
  },
  {
    value: coffee2,
    label: "coffee-2",
  },
  {
    value: coffee,
    label: "coffee",
  },
  {
    value: 11,
    label: "11 stamps",
  },
  {
    value: 12,
    label: "12 stamps",
  },
  {
    value: 13,
    label: "13 stamps",
  },
  {
    value: 14,
    label: "14 stamps",
  },
  {
    value: 15,
    label: "15 stamps",
  },
  {
    value: 16,
    label: "16 stamps",
  },
  {
    value: 17,
    label: "17 stamps",
  },
  {
    value: 18,
    label: "18 stamps",
  },
  {
    value: 19,
    label: "19 stamps",
  },
  {
    value: 20,
    label: "20 stamps",
  },
];

export default pictureStamps;
