import React, { useState } from "react";
import { CgMenuRight, CgMenuRightAlt } from "react-icons/cg";

import { FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavItem,
  NavLinksSignUp
} from "./NavbarStyles";
import { navbarData } from "../data/NavbarData";

const NavbarSub = () => {
  const [show, setShow] = useState(false);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const closeMobileMenu = (id) => {
    scrollTo(id);

    setShow(false);
  };

  return (
    <IconContext.Provider value={{ color: "red" }}>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <NavIcon src="/images/logo/logo_desktop_w.png" alt="" />
          </NavLogo>
          <MobileIcon onClick={() => setShow(!show)}>
            {show ? <FaTimes /> : <CgMenuRightAlt style={{ color: "#fff" }} />}
          </MobileIcon>
          <NavMenu show={show}>
            {navbarData.map((el, index) => (
              <NavItem key={index}>
                <NavLinks to="/" onClick={() => closeMobileMenu(el.to)}>
                  {el.text}
                </NavLinks>
              </NavItem>
            ))}
            <NavItem>
              <NavLinksSignUp to="/signin">Sign In</NavLinksSignUp>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default NavbarSub;
