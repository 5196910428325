export const URL = "https://lokalai-be-mar24.vercel.app";

// export const URL = "http://localhost:5005";

export const FrontEnd_URL = "https://lokalai.com";

// export const Socket_URL = "http://localhost:6005/"

export const Socket_URL = "https://socket-lokalai-23jul.onrender.com/"

export const GoogleAuth_URL = "http://localhost:7005/"





