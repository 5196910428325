import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QrReader from "react-qr-reader";
import {
  Avatar,
  Box,
  Divider,
  getFormControlLabelUtilityClasses,
  Typography,
} from "@mui/material";
import { URL } from "../../Redux/Url";
import axios from "axios";


import toast, { Toaster } from "react-hot-toast";
import { Button, Heading, TextWrapper } from "./globalStyles";
import { IconContext } from "react-icons/lib";
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCardInfo,
  PricingCardPlan,
  PricingCardCost,
  PricingCardFeatures,
  PricingCardText,
  PricingCardFeature,
} from "./PricingStyles";
import Tilt from "react-tilt";
import PricePackageItem from './PricePackageItem'

const MainPricePackage = ({ history, match }) => {
  const [PricePagekage, setPricePagekage] = useState([]);
  const [qty, setQty] = useState(1);
  const AddToCartHandle = (index) => {
    // e.preventDefault();
    // history.push(`/cart/${productId}?qty=${qty}`);
    console.log({ index });
  };

  useEffect(() => {
    axios.get(`${URL}/api/pricepackage/`).then((response) => {
      if (response.data.success) {
        toast.success("successull get price package");

        setPricePagekage(response.data.pricepackages);
        // console.log({ PricePagekage });
      } else {
        alert("Failed to get Subscribed Information");
      }
    });
  }, []);
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />{" "}
      <IconContext.Provider value={{ color: "#a9b3c1", size: "1rem" }}>
        <PricingSection id="pricing">
          <PricingWrapper>
            <PricingHeading>Pick Your Best Option</PricingHeading>
            <TextWrapper
              color="white"
              maxWidth="460px"
              mb="2rem"
              size="1.2rem"
              lineHeight="1.3rem"
              align="center"
              weight="600"
            >
              Listen without limits on your phone, speaker, and other devices at
              the most suitable option for you.
            </TextWrapper>
            <PricingContainer>
              {/* {console.log({PricePagekage})} */}
              {PricePagekage.map((price, index) => (
                <PricePackageItem price = {price} key={index} />
              ))}


            </PricingContainer>
          </PricingWrapper>
        </PricingSection>
      </IconContext.Provider>
    </>
  );
};

export default MainPricePackage;
