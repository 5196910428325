import React, { useEffect, useState } from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";


import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/productScreen";
import CategoriesScreen from "./screens/CategoriesScreen";
import OrderScreen from "./screens/OrderScreen";
import AddProduct from "./screens/AddProduct";
import AddProductS3 from "./screens/AddProductS3";

import UsersScreen from "./screens/UserScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import NotFound from "./screens/NotFound";
import PrivateRouter from "./PrivateRouter";
import { listProducts } from "./Redux/Actions/ProductActions";
import { listOrders } from "./Redux/Actions/OrderActions";
import SingleProduct from "./screens/SingleProduct";
import Introduce from "./screens/Introduce";
import SignUpScreen from "./screens/SignUpScreen";
import { SignInButton } from "./screens/IntroduceSubSections/Hero/HeroStyles";
import SigninScreen from "./screens/SignInScreen";
import RewardCardDesign from "./screens/RoyaltyProgram/RewardCardDesign";

import ProfileScreen from "./screens/ProfileScreen";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./components/ScrollToTop";
import LiveStamp from "./screens/RoyaltyProgram/LiveStamp";
import RoyaltyMembers from "./screens/RoyaltyProgram/RoyaltyMembers";
import PricePagekage from "./screens/PricePagekage";

///

import CartScreen from "./screens/CartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import DashboardMessages from "./screens/ShopMessages";
import ShopMessages from "./screens/ShopMessages";
import { Socket_URL } from "./Redux/Url";

//socket client
import socketIO from "socket.io-client";
import { GiCoinsPile } from "react-icons/gi";

const ENDPOINT = Socket_URL;

const socketId = socketIO(ENDPOINT, { transports: ["websocket"] });

function App() {

  const dispatch = useDispatch();
  console.log("socket APP.js :",socketId)

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listProducts());
      dispatch(listOrders());
    }
  }, [dispatch, userInfo]);

  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
      <Router>
        <Switch>
          <PrivateRouter path="/" component={HomeScreen} exact />
          <PrivateRouter path="/products" component={ProductScreen} />
          <PrivateRouter path="/category" component={CategoriesScreen} />
          <PrivateRouter path="/order/:id" component={OrderScreen} />
          <PrivateRouter path="/addproduct" component={AddProduct} />
          <PrivateRouter path="/addproducts3" component={AddProductS3} />
          <PrivateRouter path="/rewardCard" component={RewardCardDesign} />
          <PrivateRouter path="/royaltymember" component={RoyaltyMembers} />
          <PrivateRouter
            path="/product/:id/edit"
            component={ProductEditScreen}
          />
          <PrivateRouter path="/profile" component={ProfileScreen} />
          <PrivateRouter path="/product/:id" component={SingleProduct} />
          <PrivateRouter path="/pricepackage" component={PricePagekage} />
          <PrivateRouter path="/dashboard-messages" ><ShopMessages socketId={socketId}/></PrivateRouter>
          <PrivateRouter path="/livestamp" ><LiveStamp socketId={socketId}/></PrivateRouter>


          <Route path="/cart/:id?" component={CartScreen} />
          <Route path="/signin" component={SigninScreen} />
          <Route path="/signup" component={SignUpScreen} />

          <Route path="/introduce" component={Introduce} />
          


          <Route path="/shipping" component={ShippingScreen} />
          <Route path="/payment" component={PaymentScreen} />
          <Route path="/placeorder" component={PlaceOrderScreen} />
          <Route path="/order/:id" component={OrderScreen} />

          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
      <ScrollToTop />
    </>
  );
}

export default App;
