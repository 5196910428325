import React, { useEffect, useState } from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import EditProductMain from "./../components/products/EditproductMain";
// import axios from "axios";
// 
const ProductEditScreen = ({ match }) => {
  const productId = match.params.id;

  // const [product, setProduct] = useState([])
  // useEffect(()=>{
  //   // console.log("hello")
  //   const fetchproduct = async() =>{
  //     const {data} = await axios.get(`/api/products/${productId}`)
  //     console.log({data})
  //     setProduct(data)
  //   }
  //   fetchproduct()
    
  // },[])

  // console.log(product)
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <EditProductMain productId={productId} />
      </main>
    </>
  );
};
export default ProductEditScreen;
