import React from "react";
import { Container } from "../globalStyles";
import {
  FeatureText,
  FeatureSection,
  FeatureTitle,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureName,
  FeatureTextWrapper,
  FeatureParagraph,
} from "./FeaturesStyles";

import { featuresData } from "../data/FeaturesData";

const Features = () => {
  return (
    <FeatureSection id="about">
      <Container>
        <FeatureTextWrapper>
          <FeatureTitle>What We Do</FeatureTitle>
        </FeatureTextWrapper>
        <FeatureParagraph>
          To use advanced information technology such as artificial intelligent,
          blockchain and non-fungible token to supercharge retail trade
          enterprises.
        </FeatureParagraph>
        <FeatureParagraph>
          By 2033. we aim to help 1 million small and micro retail trade
          enterprises to be profitable, and to serve 100 million customers
        </FeatureParagraph>
        <FeatureWrapper>
          {featuresData.map((el, index) => (
            <FeatureColumn key={index}>
              <FeatureImageWrapper className={el.imgClass}>
                {el.icon}
              </FeatureImageWrapper>
              <FeatureName>{el.name}</FeatureName>
              <FeatureText>{el.description}</FeatureText>
            </FeatureColumn>
          ))}
        </FeatureWrapper>
      </Container>
    </FeatureSection>
  );
};

export default Features;
