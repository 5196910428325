import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import ProfileTabs from "../components/profileComponents/ProfileTabs";

import { getUserDetails } from "../Redux/Actions/userActions";
import moment from "moment";
import { listMyOrders } from "../Redux/Actions/OrderActions";
import Orders from "../components/orders/Orders";

import Sidebar from "./../components/sidebar";
import QROperatorCode from "../components/profileComponents/QROperatorCode";

const ProfileScreen = () => {
  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { photo, cover } = userInfo;
  // console.log({ userInfo });
  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading, error, orders } = orderListMy;

  // console.log({orders})

  useEffect(() => {
    dispatch(listMyOrders());
    dispatch(getUserDetails("profile"));
  }, [dispatch]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className=" mt-lg-5 mt-3">
            <div className="row align-items-start">
              <div className="content-header">
                <h2 className="content-title"> Basic Information </h2>
              </div>
              <div className="col-lg-4 p-0 shadow ">
                <div className="author-card pb-0 pb-md-3">
                  <div
                    className="author-card-cover"
                    style={{
                      backgroundImage: `url(${cover})`,
                    }}
                  ></div>
                  <div className="author-card-profile row">
                    <div className="author-card-avatar col-md-5">
                      <img src={photo} alt="userprofileimage" />
                    </div>
                    <div className="author-card-details col-md-7">
                      <h5 className="author-card-name mb-2">
                        Shop <strong>{userInfo.name}</strong>
                      </h5>
                      <span className="author-card-position">
                        <>Joined {moment(userInfo.createdAt).format("LL")}</>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="wizard pt-3 ">
                  <div class="d-flex align-items-start">
                    <div
                      class="nav align-items-start flex-column col-12 nav-pills me-3 "
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        class="nav-link active"
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        Profile Settings
                      </button>
                      <button
                        class="nav-link d-flex justify-content-between"
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="false"
                      >
                        Orders List
                        <span className="badge2">{orders ? orders.length : 0}</span>
                      </button>
                      <button
                        class="nav-link"
                        id="v-pills-QR-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-QR"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-QR"
                        aria-selected="false"
                      >
                        QR code
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* panels */}
              <div
                class="tab-content col-lg-8 pb-5 pt-lg-0 pt-3"
                id="v-pills-tabContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <ProfileTabs />
                </div>
                <div
                  class="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <Orders orders={orders} loading={loading} error={error} />


                </div>
                <div
                  class="tab-pane fade"
                  id="v-pills-QR"
                  role="tabpanel"
                  aria-labelledby="v-pills-QR-tab"
                >
                  <QROperatorCode />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ProfileScreen;
