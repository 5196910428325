import React from "react";
import { Link } from "react-router-dom";
import Hero from "./IntroduceSubSections/Hero/Hero";
import Features from "./IntroduceSubSections/Features/Features";
import { Heading } from "./IntroduceSubSections/globalStyles";
import Client from "./IntroduceSubSections/Clients/Clients";

import Content from "./IntroduceSubSections/Content/Content";
import {
  heroOne,
  heroTwo,
  heroThree,
  heroFour,
  heroFive,
} from "./IntroduceSubSections/data/HeroData";
import Footer from "./IntroduceSubSections/Footer/Footer";
import NavbarSub from './IntroduceSubSections/NavbarSub/NavbarSub';

import GlobalStyle from './IntroduceSubSections/globalStyles';


const Introduce = () => {
  return (
    <>
      {/* <header className="main-header navbar"> */}
        {/* <Link to="/" className="brand-wrap">
          <img
            src="/images/lokalai5.png"
            style={{ height: "46px" }}
            className="logo"
            alt="Ecommerce dashboard template"
          />
        </Link> */}
        
      {/* </header> */}
      <GlobalStyle />

      <NavbarSub />
      <Hero />
      <Features />
      <Content {...heroOne} />
      <Content {...heroTwo} />
      <Content {...heroThree} />
      <Content {...heroFour} />
      <Content {...heroFive} />
      <Client />
      <Footer />
    </>
  );
};

export default Introduce;
