import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import { yellow } from "@mui/material/colors";

export const themeModes = {
  dark: "dark",
  light: "light"
};

const themeConfigs = {
  custom: ({ mode }) => {
    const customPalette = mode === themeModes.dark ? {
      primary: {
        // main: "#F5E653",//"#f44336", yellow
        main: "#FFC70B",//"#f44336", yellow

        // main: "#ff0000",
        contrastText: colors.grey["700"],
        black:"#000"

      },
      secondary: {
        // main: "#F5E653",// yellow
        main: "#f44336",//"red"
        contrastText: "#ffffff"
      },
      background: {
        // default: colors.grey["200"],
        default: "#fff",
        paper: "#131313"
      }
    } : {
      primary: {
        main: "#FFC70B",//"#f44336", yellow
        // main: "#ff0000"
        contrastText: colors.grey["700"],
        black:"#fff"
      },
      secondary: {
        // main: "#F5E653",// yellow
        main: "#f44336",//"red"
        contrastText: "#000",
        
      },
      background: {
        default: colors.grey["100"],
      }
    };

    return createTheme({
      palette: {
        mode,
        ...customPalette
      },
      components: {
        MuiButton: {
          defaultProps: { disableElevation: true }
        }
      }
    });
  }
};

export default themeConfigs;