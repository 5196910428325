import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Box } from "@mui/system";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { URL } from "../../Redux/Url";
import { toast } from "react-toastify";

function JoinRoyalty(props) {
  // console.log("subcribe props :", { props });



  const userTo = props.userTo;
  const userFrom = props.userFrom
  const socket=props.socket;

  // console.log({socket})

  const [SubscribeNumber, setSubscribeNumber] = useState(0);
  const [Subscribed, setSubscribed] = useState(false);
  const [joinRoyaled, setJoinRoyaled] = useState(false);
  const [joinRoyalNumber, setJoinRoyalNumber] = useState(0);

  useEffect(() => {
    const subscribeNumberVariables = { userTo: userTo };
    //GET FOLLOW (SUBCRIBER) NUMBER
    axios
      .post(`${URL}/api/subscribe/subscribeNumber`, subscribeNumberVariables)
      .then((response) => {
        if (response.data.success) {
          setSubscribeNumber(response.data.subscribeNumber);
        } else {
          alert("Failed to get subscriber Number");
        }
      });


    if (userTo) {
      const subscribeInformationVariables = {
        userTo: userTo,
        userFrom: userFrom,
      };
      axios
        .post(
          `${URL}/api/subscribe/subscribed`,
          subscribeInformationVariables
        )
        .then((response) => {
          if (response.data.success) {
            setSubscribed(response.data.subcribed);
            // console.log("response.data.subcribed", response.data.subcribed);
            toast.info("successull get subcribed  of the user");
          } else {
            alert("Failed to get Subscribed Information");
          }
        });
    }

    //GET JOIN ROYAL PROGRAM NUMBER
    axios
      .post(`${URL}/api/royalprogram/joinNumber`, subscribeNumberVariables)
      .then((response) => {
        if (response.data.success) {
          setJoinRoyalNumber(response.data.joinNumber);
        } else {
          alert("Failed to get join Royal program Number");
        }
      });
  }, []);
  let joinVariables = {
    userTo: userTo,
    userFrom: userFrom,
  };

  axios
    .post(`${URL}/api/royalprogram/joined`, joinVariables)
    .then((response) => {
      if (response.data.success) {
        // console.log(
          // `${user.displayName} JOINED ROYAL PROGRAM :`,
          // response.data.joined
        // );
        setJoinRoyaled(response.data.joined);
      } else {
        alert("Failed to get Subscribed Information");
      }
    });

  const onSubscribe = () => {
    console.log({ Subscribed });

    let subscribeVariables = {
      userTo: userTo,
      userFrom: userFrom,
    };

    console.log(subscribeVariables);
    if (Subscribed) {
      //when we are already subscribed
      axios
        .post(`${URL}/api/subscribe/unSubscribe`, subscribeVariables)
        .then((response) => {
          if (response.data.success) {
            setSubscribeNumber(SubscribeNumber - 1);
            setSubscribed(!Subscribed);
            console.log(response.data.success);
          } else {
            alert("Failed to unsubscribe");
          }
        });
    } else {
      // when we are not subscribed yet

      axios
        .post(`${URL}/api/subscribe/subscribe`, subscribeVariables)
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.success);
            setSubscribeNumber(SubscribeNumber + 1);
            setSubscribed(!Subscribed);
          } else {
            alert("Failed to subscribe");
          }
        });
    }
  };

  const onJoinRoyal = () => {
    let joinVariables = {
      userTo: userTo,
      userFrom: userFrom,
    };

    console.log({ joinVariables });

    //GET USER ALREADY JOINED ROYAL PROGRAM

    if (joinRoyaled) {
      //when users are already joined Royalty Program -> UnJoin
      axios
        .post(`${URL}/api/royalprogram/unjoinLoyalty`, joinVariables)
        .then((response) => {
          if (response.data.success) {
            setJoinRoyalNumber(joinRoyalNumber - 1);
            setJoinRoyaled(!joinRoyaled);
            console.log(response.data.success);
          } else {
            alert("Failed to unjoin loyalty program");
          }
        });
    } else {
      // when users are not joint the Royalty Program -> JOINED

      axios
        .post(`${URL}/api/royalprogram/joinLoyalty`, joinVariables)
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.success);
            setJoinRoyalNumber(joinRoyalNumber + 1);
            setJoinRoyaled(!joinRoyaled);
          } else {
            alert("Failed to join loyalty program");
          }
        });

        //send automatic navigate to royalty page
          socket.emit("addUser", userTo);
          socket.emit("sendNavigate", {
            senderId: userTo, //shopId
            receiveId: userFrom, //scanId
            type:"/royalty",
          });
      
   }
  };

  return (
    <Box display="flex" flexDirection={{ xs: "row", md: "row", lg: "row" }}>
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        {/* <span
          style={{
            textTransform: "capitalize",
            fontWeight: "600",
          }}
        >
          {props.operatorname}
        </span> */}
        <small>
          {SubscribeNumber} followers{" "}
          {Subscribed ? `${joinRoyalNumber} joined Royalty` : null}{" "}
        </small>
      </Box>
      <Box
        onClick={onSubscribe}
        sx={{
          backgroundColor: `${Subscribed ? "transparent" : "yellow"}`,
          color: "inherit",
          borderRadius: "25px",
          padding: "5px",
          margin: "auto",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "5px 10px 5px  10px",
            textTransform: "capitalize",
            fontSize: "0.8rem",
          }}
        >
          {/* {Subscribed ? <NotificationsOutlinedIcon /> : null} */}
          {Subscribed ? <HowToRegIcon /> : "follow"}
        </span>
      </Box>
      {Subscribed && (
        <>
          <Box
            onClick={onJoinRoyal}
            sx={{
              backgroundColor: `${
                joinRoyaled ? "transparent" : "primary.main"
              }`,
              border: `${joinRoyaled ? "1px solid black" : null}`,
              color: "inherit",
              borderRadius: "25px",
              padding: "5px",
              margin: "auto",
              width: "70px",
            }}
          >
            {/* {SubscribeNumber} {Subscribed ? 'followed' : 'follow'} */}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 10px",
                justifyContent: "center",
                textTransform: "capitalize",
                fontSize: "0.8rem",
              }}
            >
              {joinRoyaled ? "Joined" : "Join"}
            </span>
          </Box>
        </>
      )}
    </Box>
  );
}

export default JoinRoyalty;
