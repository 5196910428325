import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import MainPricePackage from "../components/PricePackage/MainPricePackage";
const PricePagekage = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <MainPricePackage />
      </main>
    </>
  );
};

export default PricePagekage;
