import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../Redux/Actions/userActions";
import moment from "moment";
import QrReader from "react-qr-reader";
import { Box, Button } from "@mui/material";
import UserQRInfo from "../../components/RewardCard/UserQRInfo";
import LiveReward from "../../components/RewardCard/LiveReward";

const LiveStamp = ({socketId}) => {
  // console.log("LiveStamp :",socketId)
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { photo, cover } = userInfo;
  // console.log({ userInfo });
  // const orderListMy = useSelector((state) => state.orderListMy);
  // const { loading, error, orders } = orderListMy;

  useEffect(() => {
    // dispatch(listMyOrders());
    dispatch(getUserDetails("profile"));
  }, [dispatch]);

  const [scanResultWebCam, setScanResultWebCam] = useState("");

  const handleErrorWebCam = (error) => {
    console.log(error);
  };
  const handleScanWebCam = (result) => {
    if (result) {
      setScanResultWebCam(result);
    }
  };



  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className=" mt-lg-5 mt-3">
            <div className="row align-items-start">
              <div className="content-header">
                <h2 className="content-title"> Live Stamping Rewards </h2>
              </div>
              <div className="col-lg-4 p-0 shadow ">
                <div className="author-card pb-0 pb-md-3">
                  <div
                    className="author-card-cover"
                    style={{
                      backgroundImage: `url(${cover})`,
                    }}
                  ></div>
                  <div className="author-card-profile row">
                    <div className="author-card-avatar col-md-5">
                      <img src={photo} alt="userprofileimage" />
                    </div>
                    <div className="author-card-details col-md-7">
                      <h5 className="author-card-name mb-0">
                        Shop <strong>{userInfo.name}</strong>
                      </h5>
                      <span className="author-card-position">
                        <>Joined {moment(userInfo.createdAt).format("LL")}</>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="wizard pt-3 ">
                  <div class="d-flex align-items-start">
                    <div
                      class="nav align-items-start flex-column col-12 nav-pills me-3 "
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        class="nav-link active"
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        QR Scan
                      </button>
                      {/* <button
                        class="nav-link d-flex justify-content-between"
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="false"
                      >
                        Orders List
                        <span className="badge2">10</span>
                      </button> */}
                      <button
                        class="nav-link d-flex justify-content-between"
                        id="v-pills-QR-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-QR"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-QR"
                        aria-selected="false"
                      >
                        Live Stamp (Maximum Stamps)
                        <span className="badge2">10</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* panels */}
              <div
                class="tab-content col-lg-8 pb-5 pt-lg-0 pt-3"
                id="v-pills-tabContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <div>
                    <h4>SCAN QR CODE</h4>
                    <hr></hr>
                    {scanResultWebCam === "" ? (
                      <>
                        <p>Qr Code Scan by Web Cam</p>
                        <Box
                          sx={{
                            width: { xs: "300px", md: "400px" },
                            margin: "0 auto",
                          }}
                        >
                          <QrReader
                            delay={100}
                            style={{ margin: "0 auto" }}
                            onError={handleErrorWebCam}
                            onScan={handleScanWebCam}
                          />
                        </Box>
                      </>
                    ) : (
                      <Box margin="1rem">
                        <Button
                          variant="contained"
                          sx={{ background: "#FFC70B", color: "#000" }}
                          onClick={() => setScanResultWebCam("")}
                        >
                          RESCAN
                        </Button>

                        <p>
                          <br />
                          Scanned QR Code resulte:
                          <p style={{ color: "orange" }}>
                            {scanResultWebCam}
                          </p>{" "}
                        </p>
                        <hr /> 
                        <UserQRInfo
                          scanId={scanResultWebCam}
                          shopName={userInfo.name}
                          shopId={userInfo._id}
                          socket={socketId}
                        />
                      </Box>
                    )}
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="v-pills-QR"
                  role="tabpanel"
                  aria-labelledby="v-pills-QR-tab"
                >
                  {scanResultWebCam !== "" ? (
                    <>
                      <LiveReward
                        userTo={scanResultWebCam}
                        userFrom={userInfo._id}
                        shopName={userInfo.name}
                        socket={socketId}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default LiveStamp;
