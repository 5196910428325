import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Actions/userActions";
import { useSelector } from "react-redux";
import { themeModes } from "../configs/theme.configs";
import { setThemeMode } from "../Redux/features/themeModeSlice";
import Switch from "@mui/material/Switch";
import { GoogleAuth_URL } from "../Redux/Url";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";

const Header = ({ socketId }) => {
  //notification section
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);

  // console.log({ socketId });

  useEffect(() => {
    if (!socketId) return;
    socketId.on("getNotification", (data) => {
      // console.log({ data });
      setNotifications((prev) => [...prev, data]);
      // console.log({ notifications });
    });
  }, [socketId]);

  const displayNotification = ({ senderName, type }) => {
    let action;

    if (type === 1) {
      action = "liked";
    } else if (type === 2) {
      action = "commented";
    } else {
      action = "message";
    }
    return (
      <span className="notification">{`${senderName} ${action} you a text.`}</span>
    );
  };

  const handleRead = () => {
    setNotifications([]);
    setOpen(false);
  };

  // end of notification section

  const label = { inputProps: { "aria-label": "Size switch demo" } };

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const OnSwithTheme = () => {
    const { themeMode } = useSelector((state) => state.themeMode);

    const theme =
      themeMode === themeModes.dark ? themeModes.light : themeModes.dark;
    dispatch(setThemeMode(theme));
  };
  useEffect(() => {
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");
    });

    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        // minimize sidebar on desktop
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  const userInfor = useSelector((state) => state.userLogin);
  // console.log({userInfor})

  const { name, photo, email } = userInfor.userInfo;

  const logoutHandler = () => {
    dispatch(logout());
    // axios.post(`${GoogleAuth_URL}auth/logout`, { name, email})

    const subscribeNumberVariables = { email: email };
    //GET FOLLOW (SUBCRIBER) NUMBER
    axios.post(`${GoogleAuth_URL}auth/logout`).then((response) => {
      console.log(response);
      // if (response.data.success) {
      //   setSubscribeNumber(response.data.subscribeNumber);
      // } else {
      //   alert("Failed to get subscriber Number");
      // }
    });
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <header
      className="main-header navbar"
      style={{ background: "linear-gradient(to top right, #FFC70B, #f44336)" }}
    >
      <div className="col-search">
        <h4 style={{ marginLeft: "2rem", fontWeight: 700, fontSize: "2rem" }}>
          LOKALAI ADMIN DASHBOARD
        </h4>
        {/* <form className="searchform">
          <div className="input-group">
            <input
              list="search_terms"
              type="text"
              className="form-control"
              placeholder="Search term"
            />
            <button className="btn btn-light bg" type="button">
              <i className="far fa-search"></i>
            </button>
          </div>
        </form> */}
      </div>
      <div className="col-nav">
        <div className="d-flex align-items-center">
          <button
            className="btn btn-icon btn-mobile"
            data-trigger="#offcanvas_aside"
          >
            <i className="md-28 fas fa-bars"></i>
          </button>
          {!isNonMobile && (
            <>
              <div className="mx-3">
                <Link to="/profile">
                  <img
                    src="/images/logo/logo_mobile_b.png"
                    style={{ height: "30px" }}
                    alt="Ecommerce dashboard template"
                  />
                </Link>
              </div>
            </>
          )}
        </div>
        {/* <Link to="/cart" className="cart-mobile-icon">
          <i className="fas fa-shopping-bag"></i>
          <span className="badge">{cartItems.length}</span>
        </Link> */}
        <ul className="nav">
          {/* <li className="nav-item">
            <Link className="nav-link btn-icon" to="/profile">
              <i class="fa fa-qrcode" aria-hidden="true"></i>
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <Switch
              {...label}
              defaultChecked
              size="small"
              // onChange={change}
              // checked={check}
            />
          </li> */}
          <li className="nav-item">
            <NavLink className="nav-link btn-icon" to="#">
              <i className="fas fa-bell" onClick={() => setOpen(!open)}></i>
              {notifications.length > 0 && (
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    padding: "5px",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                  }}
                >
                  {notifications.length}
                </div>
              )}

              {open && (
                <div className="notifications">
                  {notifications.map((n) => displayNotification(n))}
                  <button className="nButton" onClick={handleRead}>
                    Mark as read
                  </button>
                </div>
              )}
            </NavLink>
          </li>
          {isNonMobile && (
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/profile"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <small>Welcome</small>
                <p style={{ fontWeight: 500 }}>{name}</p>
              </Link>
            </li>
          )}
          {/* <li>
            <Link to="/cart" className="cart-mobile-icon">
              <i className="fas fa-shopping-bag"></i>
              <span className="badge1">{cartItems.length}</span>
            </Link>
          </li> */}

          <li className="dropdown nav-item">
            <Link className="dropdown-toggle" data-bs-toggle="dropdown" to="#">
              <img className="img-xs rounded-circle" src={photo} alt="User" />
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item" to="/profile">
                My profile
              </Link>
              <Link className="dropdown-item" to="#">
                Settings
              </Link>
              <Link
                onClick={logoutHandler}
                className="dropdown-item text-danger"
                to="#"
              >
                Exit
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
