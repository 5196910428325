import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
`;

export const Container = styled.div`
	width: 100%;
	max-width: 1300px;
	margin: 0 auto;
	padding: 0 50px;

	@media screen and (max-width: 960px) {
		padding: 0 30px;
	}
`;

export const Section = styled.div`
	color: #fff;
	padding: 160px 0;
`;

export const Heading = styled.h2`
	margin-bottom: 1.4rem;
	font-size: 3rem;
	text-align: center;
	line-height: 1.1;
	font-weight: 600;
	color: ${({ lightText }) => (lightText ? '#f7f8fa' : '1c2237')};
`;

export const Button = styled.button`
	border-radius: 4px;
	// background: none;
	white-space: nowrap;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	outline: none;
	border: 2px solid #fff;
	cursor: pointer;
	overflow: hidden;
	position: relative;

	// text-transform: uppercase;
	border-radius: 10px;
	border: none;
	background-color: white;
	color: #f44336;
	cursor: pointer;
	transition: all 0.4s ease-in;

	&:before {
		background: #fff;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		transition: all 0.6s ease;
		width: 100%;
		height: 0%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:hover:before {
		height: 500%;
	}

	&:hover {
		color: black;
		box-shadow: inset 200px 0 0 0 #f44336;
		transition: box-shadow 0.5s ease-in;
		background-color: #f44336;
		color: white;
	}
`;

export default GlobalStyle;
