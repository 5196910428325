import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import ImageUploadS3 from "./ImageUploadS3";
import VideoUploadS3 from "./VideoUploadS3";
import { Box, Button, Chip, Divider, Stack, useTheme } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "./Container";
import Toast from "../LoadingError/Toast";
import { toast } from "react-toastify";
import { PRODUCT_CREATE_RESET } from "../../Redux/Constants/ProductConstants";
import { createProduct } from "./../../Redux/Actions/ProductActions";
import Title from "antd/es/skeleton/Title";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const UploadS3 = () => {
  // BUTTONS SHOW SECTIONS
  const [show1, setShow1] = useState(true);
  const [arrow1, setArrow1] = useState(true);
  const section1Open = () => {
    setShow1(!show1);
    setArrow1(!arrow1);
  };

  const [show2, setShow2] = useState(true);
  const [arrow2, setArrow2] = useState(true);
  const section2Open = () => {
    setShow2(!show2);
    setArrow2(!arrow2);
  };


  const [show3, setShow3] = useState(true);
  const [arrow3, setArrow3] = useState(true);
  const section3Open = () => {
    setShow3(!show3);
    setArrow3(!arrow3);
  };

  const [show4, setShow4] = useState(true);
  const [arrow4, setArrow4] = useState(true);
  const section4Open = () => {
    setShow4(!show4);
    setArrow4(!arrow4);
  };

  const [show5, setShow5] = useState(true);
  const [arrow5, setArrow5] = useState(true);
  const section5Open = () => {
    setShow5(!show5);
    setArrow5(!arrow5);
  };

  const [show6, setShow6] = useState(true);
  const [arrow6, setArrow6] = useState(true);
  const section6Open = () => {
    setShow6(!show6);
    setArrow6(!arrow6);
  };

  const [show7, setShow7] = useState(true);
  const [arrow7, setArrow7] = useState(true);
  const section7Open = () => {
    setShow7(!show7);
    setArrow7(!arrow7);
  };

  const [show8, setShow8] = useState(true);
  const [arrow8, setArrow8] = useState(true);
  const section8Open = () => {
    setShow8(!show8);
    setArrow8(!arrow8);
  };

  const [Images, setImages] = useState([]);
  const [Videos, setVideos] = useState([]);
  const [title, setTitle] = useState("Example1");
  const [description, setDescription] = useState("Description Example 1");
  const [shortDescription, setShortDescription] = useState(" Short Description Example 1");
  const [Phone, setPhone] = useState("+64123456789");
  const [Email, setEmail] = useState("kenshop@gmail.com");
  const [Address, setAddress] = useState("Christchurch, New Zealand");
  const [ProductNote, setProductNote] = useState("suitable for all ages");
  const [PricingNote, setPricingNote] = useState("include GST 7%");
  const [Hightlight, setHightlight] = useState("best in service");
  const [Restriction, setRestriction] = useState("maximum 2 times per day");

  // console.log({ Images }, { Videos }, { title }, { description });

  const updateImages = (newImages) => {
    // console.log("updateImages :", newImages);
    setImages(newImages);
  };

  const updateVideos = (newVideos) => {
    // console.log("updateVideos :", newVideos);
    setVideos(newVideos);
  };

  const onTitleChange = (event) => {
    setTitle(event.currentTarget.value);
  };

  const onShortDescriptionChange = (event) => {
    setShortDescription(event.currentTarget.value);
  };

  const onDescriptionChange = (event) => {
    setDescription(event.currentTarget.value);
  };

  const onPhoneChange = (event) => {
    setPhone(event.currentTarget.value);
  };

  const onEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  const onAddressChange = (event) => {
    setAddress(event.currentTarget.value);
  };

  const onProductNoteChange = (event) => {
    setProductNote(event.currentTarget.value);
  };

  const onPricingNoteChange = (event) => {
    setPricingNote(event.currentTarget.value);
  };

  const onHighlightChange = (event) => {
    setHightlight(event.currentTarget.value);
  };

  const onRestrictionChange = (event) => {
    setRestriction(event.currentTarget.value);
  };

  // ADD PRODUCT
  const dispatch = useDispatch();

  const productCreate = useSelector((state) => state.productCreate);
  const { loading, error, product } = productCreate;

  const userInfor = useSelector((state) => state.userLogin);
  // console.log({userInfor})

  const { name, photo, email, _id } = userInfor.userInfo;

  useEffect(() => {
    if (product) {
      toast.success("Product Added", ToastObjects);
      dispatch({ type: PRODUCT_CREATE_RESET });
      setImages([]);
      setVideos([]);
      // setTitleValue("");
      // setDescriptionValue("");
      // setShortDescriptionValue("");
    }
  }, [product, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();

    const variables = {
      operator: _id,
      title: title,
      desc: description,
      shortDescription: shortDescription,
      images: Images,
      videos: Videos
  }
    dispatch(
      createProduct(
 variables
      )
    );
  };

  const productCreate1 = useSelector((state) => state.productCreate);
  // console.log({productCreate1})
  return (
    <>
      <Toast />
      <section className="content-main">
        <form
          onSubmit={submitHandler}
          style={{ display: "flex", flexDirection: " column" }}
        >
          <div
            className="content-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "3px solid #FFC70B",
            }}
          >
            <h2 className="content-title">Add New Products</h2>
            <div>
              <Stack
                spacing={1}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  sx={{
                    color: "primary.contrastText",
                    margin: "0 auto",
                  }}
                  component={Link}
                  to={"/profile"}
                  variant="contained"
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    color: "primary.contrastText",
                  }}
                  component={Link}
                  to={"/edit"}
                  variant="contained"
                >
                  Edit
                </Button>
                <button type="submit" className="btn btn-primary">
                Publish now
              </button>
              </Stack>
            </div>
          </div>
          {/* Section 1 */}
          <Button
            onClick={section1Open}
            variant="contained"
            size="large"
            sx={{
              width: "auto",
              maxWidth: "max-content",
              justifyContent: "start",
              backgroundColor: "transparent",
              color: "primary.contrastText",
              fontWeight: "600",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "black",
              },
              textAlign: "left",
            }}
            startIcon={arrow1 ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          >
            <span style={{ width: "25rem" }}>
              Product Name, Short and Full Descriptions
            </span>
          </Button>
          {show1 ? (
            <div class1="reviews-wrapper home-wrapper-2">
              <div className="row" style={{ border: "0.1px solid lightgrey" }}>
                <div className="col-12">
                  <div className="review-inner-wrapper">
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                        marginTop: "0.8rem",
                      }}
                    >
                      <h4 className="mb-2" style={{ margin: "0" }}>
                        Product Name
                      </h4>
                      <p>
                        Keep it simple, descriptive and as short as possible.
                      </p>
                      <p
                        style={{
                          fontSize: "0.8rem",
                          marginBottom: "0px",
                          fontStyle: "Italic",
                        }}
                      >
                        Product name
                      </p>

                      <input
                        type="search"
                        placeholder="Search..."
                        className="form-control p-2"
                        value={title}
                        onChange={onTitleChange}
                      />
                    </div>
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <div>
                        <h4 className="mb-2" style={{ margin: "0" }}>
                          Catchy intro text
                        </h4>
                        <div className="d-flex align-items-center ">
                          <p style={{ padding: "0" }}>
                            A catchy sentence or two to introduce people to your
                            product. This text will appear with your primary
                            image on category pages and search pages and as the
                            first paragraph on your main product page.
                          </p>
                        </div>
                      </div>
                      <p
                        style={{
                          fontSize: "0.8rem",
                          marginBottom: "0px",
                          fontStyle: "Italic",
                        }}
                      >
                        Short Description
                      </p>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={onDescriptionChange}
                        defaultValue={description}
                      ></textarea>
                    </div>
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <div>
                        <h4 className="mb-2" style={{ margin: "0" }}>
                          Full description
                        </h4>
                        <div className="d-flex align-items-center ">
                          <p style={{ padding: "0" }}>
                            Describe your product clearly and in full detail for
                            potential customers.
                          </p>
                        </div>
                      </div>
                      <p
                        style={{
                          fontSize: "0.8rem",
                          marginBottom: "0px",
                          fontStyle: "Italic",
                        }}
                      >
                        Short Description
                      </p>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={onShortDescriptionChange}
                        defaultValue={shortDescription}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Section 2 */}
          <Button
            onClick={section2Open}
            variant="contained"
            size="large"
            sx={{
              width: "auto",
              maxWidth: "max-content",
              justifyContent: "start",
              backgroundColor: "transparent",
              color: "primary.contrastText",
              fontWeight: "600",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "black",
              },
              textAlign: "left",
            }}
            startIcon={arrow2 ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          >
            <span style={{ width: "25rem" }}>Photos and Videos</span>
          </Button>
          {show2 ? (
            <div class1="reviews-wrapper home-wrapper-2">
              <div className="row" style={{ border: "0.1px solid lightgrey" }}>
                <div className="col-12">
                  <div className="review-inner-wrapper">
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                        marginTop: "0.8rem",
                      }}
                    >
                      <h4 className="mb-2" style={{ margin: "0" }}>
                        Upload product photos
                      </h4>
                      <p>As many as possile (minimum 10 photos)</p>
                      <p
                        style={{
                          fontSize: "0.8rem",
                          marginBottom: "0px",
                          fontStyle: "Italic",
                        }}
                      >
                        Upload image to this area
                      </p>
                      <div>
                        {/* UPLOAD IMAGES TO AMAZON S3S */}
                        <ImageUploadS3 refreshFunction={updateImages} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ border: "0.1px solid lightgrey" }}>
                <div className="col-12">
                  <div className="review-inner-wrapper">
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                        marginTop: "0.8rem",
                      }}
                    >
                      <h4 className="mb-2" style={{ margin: "0" }}>
                        Upload product videos
                      </h4>
                      <p> at least one video)</p>
                      <p
                        style={{
                          fontSize: "0.8rem",
                          marginBottom: "0px",
                          fontStyle: "Italic",
                        }}
                      >
                        Upload videos to this area
                      </p>
                      <div>
                        {/* UPLOAD VIDEOS TO AMAZON S3S */}
                        <VideoUploadS3 refreshFunction={updateVideos} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* Section 3 */}
          <Button
            onClick={section3Open}
            variant="contained"
            size="large"
            sx={{
              width: "auto",
              maxWidth: "max-content",
              justifyContent: "start",
              backgroundColor: "transparent",
              color: "primary.contrastText",
              fontWeight: "600",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "black",
              },
              textAlign: "left",
            }}
            startIcon={arrow3 ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          >
            <span style={{ width: "25rem" }}>Operator Contact Details</span>
          </Button>
          {show3 ? (
            <div class1="reviews-wrapper home-wrapper-2">
              <div className="row" style={{ border: "0.1px solid lightgrey" }}>
                <div className="col-12">
                  <div className="review-inner-wrapper">
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                        marginTop: "0.8rem",
                      }}
                    >
                      <h4 className="mb-2" style={{ margin: "0" }}>
                        Contact Phone
                      </h4>
                      <input
                        type="input"
                        value={Phone}
                        className="form-control p-2"
                        onChange={onPhoneChange}
                      />
                    </div>
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <h4 className="mb-2" style={{ margin: "0" }}>
                        Contact Email
                      </h4>
                      <input
                        type="input"
                        value={Email}
                        className="form-control p-2"
                        onChange={onEmailChange}
                      />
                    </div>
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <div>
                        <h4 className="mb-2" style={{ margin: "0" }}>
                          Contact Address
                        </h4>
                      </div>
                      <p
                        style={{
                          fontSize: "0.8rem",
                          marginBottom: "0px",
                          fontStyle: "Italic",
                        }}
                      >
                        Operator Address
                      </p>
                      <textarea
                        class="form-control"
                        rows="3"
                        onChange={onAddressChange}
                        defaultValue={Address}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* Section 4 */}
          <Button
            onClick={section4Open}
            variant="contained"
            size="large"
            sx={{
              width: "auto",
              maxWidth: "max-content",
              justifyContent: "start",
              backgroundColor: "transparent",
              color: "primary.contrastText",
              fontWeight: "600",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "black",
              },
              textAlign: "left",
            }}
            startIcon={arrow4 ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          >
            <span style={{ width: "25rem" }}>Product and Pricing Notes</span>
          </Button>
          {show4 ? (
            <div class1="reviews-wrapper home-wrapper-2">
              <div className="row" style={{ border: "0.1px solid lightgrey" }}>
                <div className="col-12">
                  <div className="review-inner-wrapper">
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <div>
                        <h4 className="mb-2" style={{ margin: "0" }}>
                          Booking notes
                        </h4>
                        <div className="d-flex align-items-center ">
                          <p style={{ padding: "0" }}>
                            Please include any other important information that
                            customers need to know before making a booking. This
                            may include age, height or fitness requirements and
                            restrictions, as well as any other information that
                            is important for your customers to know prior to
                            booking.
                          </p>
                        </div>
                      </div>
                      <textarea
                        class="form-control"
                        rows="3"
                        onChange={onProductNoteChange}
                        defaultValue={ProductNote}
                      />
                    </div>
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <div>
                        <h4 className="mb-2" style={{ margin: "0" }}>
                          Pricing notes
                        </h4>
                        <div className="d-flex align-items-center ">
                          <p style={{ padding: "0" }}>
                            This text appears below your price info in the
                            product description and is useful for any extra
                            pricing information that customers may require when
                            making a booking.
                          </p>
                        </div>
                      </div>
                      <textarea
                        class="form-control"
                        rows="3"
                        onChange={onPricingNoteChange}
                        defaultValue={PricingNote}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Section 5 */}
          <Button
            onClick={section5Open}
            variant="contained"
            size="large"
            sx={{
              width: "auto",
              maxWidth: "max-content",
              justifyContent: "start",
              backgroundColor: "transparent",
              color: "primary.contrastText",
              fontWeight: "600",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "black",
              },
              textAlign: "left",
            }}
            startIcon={arrow5 ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          >
            <span style={{ width: "25rem" }}>Highlights and Restrictions</span>
          </Button>
          {show5 ? (
            <div class1="reviews-wrapper home-wrapper-2">
              <div className="row" style={{ border: "0.1px solid lightgrey" }}>
                <div className="col-12">
                  <div className="review-inner-wrapper">
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <div>
                        <h4 className="mb-2" style={{ margin: "0" }}>
                          What's included
                        </h4>
                        <div className="d-flex align-items-center ">
                          <p style={{ padding: "0" }}>
                            Let customers know clearly what is included in the
                            purchase price.
                          </p>
                        </div>
                      </div>
                      <textarea
                        class="form-control"
                        rows="3"
                        onChange={onHighlightChange}
                        defaultValue={Hightlight}
                      />
                    </div>
                    <div
                      className="review-head d-flex justify-content-between align-items-start flex-column"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <div>
                        <h4 className="mb-2" style={{ margin: "0" }}>
                          What should not they bring?
                        </h4>
                        <div className="d-flex align-items-center ">
                          <p style={{ padding: "0" }}>
                            Add any other restrictions that apply.{" "}
                          </p>
                        </div>
                      </div>
                      <textarea
                        class="form-control"
                        rows="3"
                        onChange={onRestrictionChange}
                        defaultValue={Restriction}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* Section 6 */}
          <Button
            onClick={section6Open}
            variant="contained"
            size="large"
            sx={{
              width: "auto",
              maxWidth: "max-content",
              justifyContent: "start",
              backgroundColor: "transparent",
              color: "primary.contrastText",
              fontWeight: "600",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "black",
              },
              textAlign: "left",
            }}
            startIcon={arrow6 ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          >
            <span style={{ width: "25rem" }}>Live Previews</span>
          </Button>
          {show6 ? (
            <div class1="reviews-wrapper home-wrapper-2">
              <div className="row" style={{ border: "0.1px solid lightgrey" }}>
                <div className="col-12">
                  <div className="review-inner-wrapper">
                    <div
                      className="review-head d-flex justify-content-between align-items-end"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <h4 className="mb-2">Preview page</h4>
                    </div>
                    <div
                      className="review-head d-flex justify-content-between align-items-end"
                      style={{
                        borderLeft: "5px solid #FFC70B",
                        paddingLeft: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      <h4 className="mb-2">Booking Confirmation Details</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </form>
        {/* OLD SECITON, WILL BE DELETED LATER */}
        {/* <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white ">
          <div className="row gx-3 py-3">
            <div className="col-lg-4 col-md-6 me-auto ">
              <input
                type="search"
                placeholder="Search..."
                className="form-control p-2"
              />
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>All category</option>
                <option>Electronics</option>
                <option>Clothings</option>
                <option>Something else</option>
              </select>
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Latest added</option>
                <option>Cheap first</option>
                <option>Most viewed</option>
              </select>
            </div>
          </div>
        </header>

        <div className="card-body">
          {errorDelete && (
            <Message variant="alert-danger">{errorDelete}</Message>
          )}
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <div className="row">
              {products.map((product) => (
                <Product product={product} key={product.id} />
              ))}
            </div>
          )}

          <nav className="float-end mt-4" aria-label="Page navigation">
            <ul className="pagination">
              <li className="page-item disabled">
                <Link className="page-link" to="#">
                  Previous
                </Link>
              </li>
              <li className="page-item active">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  Next
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div> */}
      </section>
    </>
  );
};

export default UploadS3;
