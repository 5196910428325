import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listUser } from "../../Redux/Actions/userActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { URL } from "../../Redux/Url";
import { toast } from "react-toastify";
import axios from "axios";
import { Avatar } from "@mui/material";
import moment from "moment";

const ListRoyaltyMember = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log({ userInfo });
  const [royaltyMembers, setRoyaltyMembers] = useState([]);

  const loading = false;
  const error = false;

  //GET A LIST OF ROYAL MEMEBER WHO JOINED PROGRAM OF THE "USER TO" (SHOP_ID)
  let variable = { userTo: userInfo._id };

  useEffect(() => {
    axios
      .post(`${URL}/api/royalprogram/getListRoyaltyMember`, variable)
      .then((response) => {
        if (response.data.success) {
          toast.success("Successfull get list of Royalty Members");
          setRoyaltyMembers(response.data.royalmember);
          console.log({ royaltyMembers });
        } else {
          alert("Failed to get list of Royalty Members ");
        }
      });
  }, []);
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Customers</h2>
        <div>
          <Link to="#" className="btn btn-primary">
            <i className="material-icons md-plus"></i> Create new
          </Link>
        </div>
      </div>

      <div className="card mb-4">
        <header className="card-header">
          <div className="row gx-3">
            <div className="col-lg-4 col-md-6 me-auto">
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
              />
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Show 20</option>
                <option>Show 30</option>
                <option>Show 40</option>
                <option>Show all</option>
              </select>
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Status: all</option>
                <option>Active only</option>
                <option>Disabled</option>
              </select>
            </div>
          </div>
        </header>

        {/* Card */}
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <tbody>
                {royaltyMembers.map((royaltyMember) => (
                  <tr key={royaltyMember.id}>
                    <td>
                      <b>Royalty Member</b>
                    </td>
                    <td>
                      <Avatar src={royaltyMember.photo} />
                    </td>

                    <td>{royaltyMember.displayName}</td>
                    <td>{royaltyMember.username}</td>
                    <td>{royaltyMember.gender}</td>
                    <td>
                      <span className="badge rounded-pill alert-success">
                        Joined at {moment(royaltyMember.createdAt).format("LL")}
                      </span>
                    </td>
                                        <td className="d-flex justify-content-end align-item-center">
                      <Link to={`/order`} className="text-success">
                        <i className="fas fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

                    </div>
        </div>
      </div>
    </section>
  );
};

export default ListRoyaltyMember;
