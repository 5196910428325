import React, {useState} from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
// import AddProductMain from "../components/products/AddProductMain";
import UploadS3 from "../components/products/UploadS3";
import Footer from "../components/Footer/Footer"
import { createTheme, ThemeProvider } from "@mui/material/styles";
import themeConfigs from "../configs/theme.configs";
import { Paper, Box } from "@mui/material";



const AddProductS3 = () => {
  const [daskMode, setDarkMode] = useState(false);

  return (
    <ThemeProvider
    theme={themeConfigs.custom({ mode: daskMode ? "dark" : "light" })}
  >    <Paper>
      <Sidebar />
      <main className="main-wrap">
      <Header  check={daskMode} change={()=> setDarkMode(!daskMode)}/>
        <UploadS3 />
        <Footer />
      </main>
      </Paper>
    </ThemeProvider>
  );
};

export default AddProductS3;
