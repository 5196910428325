import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import DashboardMessage from "../components/Message/DashboardMessage";
const ShopMessages = ({socketId}) => {
  // console.log("Shop Message :", socketId)
  return (
    <>
      <Sidebar />
      <main className="main-wrap">    
        <Header socketId={socketId}/>
        {window.scrollTo(0, 0)}
        <DashboardMessage socketId={socketId}/>
        <Footer />
      </main>
    </>
  );
};

export default ShopMessages;
