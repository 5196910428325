import React, { useEffect, useState } from "react";
import Stamps from "../RewardCardDesign/section/stamps";
import axios from "axios";
import { URL } from "../../Redux/Url";
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import toast, { Toaster } from "react-hot-toast";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  maxWidth: 370,
  minWidth: 370,
}));

const LiveReward = (props) => {
  // console.log({ props });
  const userTo = props.userTo;
  const userFrom = props.userFrom;
  const shopName = props.shopName;
  const socketId = props.socket;

  // console.log("live reward", socketId)

  const [StampAwardNumber, setStampAwardNumber] = useState(0);
  const [stampExist, setstampExist] = useState(0);
  const [userInfo, setUserInfo] = useState([]);

  let userInforVariable = { userId: props.userTo };

  const [stampDesign, setStampDesign] = useState([]);

  useEffect(() => {
    axios
      .post(`${URL}/api/v1/user/userInfo`, userInforVariable)
      .then((response) => {
        if (response.data.success) {
          toast.success("successful has User Information");
          // console.log(response.data.user);
          setUserInfo(response.data.user);
        } else {
          alert("Failed to get subscription products");
        }
      });

    const stampAwardNumberVariables = { userTo: userTo, userFrom: userFrom };
    axios
      .post(`${URL}/api/stamp/stampAwardNumber`, stampAwardNumberVariables)
      .then((response) => {
        if (response.data.success) {
          setStampAwardNumber(response.data.stampAwardNumber);
          setstampExist(response.data.stampAwardNumber);
        } else {
          alert("Failed to get subscriber Number");
        }
      });

    //GET CARD DESIGN INFORMATION

    let designVariable = { store: userFrom };
    // console.log(variable)
    axios
      .post(`${URL}/api/royalprogram/getStampDesign_by_Id`, designVariable)
      .then((response) => {
        if (response.data.success) {
          // console.log("getStampDesign", response.data.cardDesignItem);
          setStampDesign(response.data.cardDesignItem);
          toast.success("successful get Card Design information");
        } else {
          alert("Failed to get stamp Design information from Server");
        }
      });
  }, []);

  const { CardColor, NoofStamps, StampColor, unStampColor, cardDesc1,PickImg
  } =
    stampDesign;

  // {
  //   console.log({ CardColor });
  // }
  const [colorCard, setColorCard] = useState(CardColor);
  const [colorStamps, setColorStamp] = useState("");
  const [colorunStamps, setColorunStamp] = useState("#FFC70B");
  const StoreAvatar = localStorage.getItem("avatar");

  const [stamps, setStamps] = useState(10);
  const [pickImg, setPickImg] = useState();
  const [pickunImg, setPickunImg] = useState();

  // const [cardDesc1, setCardDesc1] = useState("Loyalty Rewards Card");
  const [cardDesc2, setCardDesc2] = useState(
    "Get 1 stamp for every $10 spent in store."
  );
  const [cardDesc3, setCardDesc3] = useState();
  const [cardDesc4, setCardDesc4] = useState(
    "Get $10 off your next purchase after collecting all stamps."
  );

  // Support section
  const [cardDesc5, setCardDesc5] = useState(
    "1. Get 1 stamp for every $5 spent.  2. Collect all stamps to receive $10 off your next purchase. 3. Reward can only be redeemed in full. Partial use of a reward is not permitted. 4. Stamps and rewards cannot be exchanged, refunded, replaced or redeemed for cash. 5. We reserve the right of final decisions in case of any disputes."
  );

  const [src, setSrc] = useState("");

  //Stamp section

  const [stampCount, setStampCount] = useState(0);
  const [StampAward, setStampAward] = useState(stampCount);

  const decrease = () => {
    setStampCount((prevCount) => prevCount - 1);
    setStampAward(StampAward - 1);
  };

  const increase = () => {
    setStampCount((prevCount) => prevCount + 1);
    setStampAward(StampAward + 1);
  };

  const onStampAward = () => {

    if (stampExist + stampCount < NoofStamps) {
      
      setStampCount((prevCount) => prevCount + 1);
      setStampAward(StampAward + 1);
  
      let stampVariables = {
        userTo: userTo,
        userFrom: userFrom,
      };

      socketId.emit("addUser", userFrom);
      socketId.emit("sendLiveStamp", {
        senderId: userFrom,
        senderName: shopName,
        receiverId: userTo,
        stamp:1,
      });  

      axios
        .post(`${URL}/api/stamp/stampAward`, stampVariables)
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.success);
            // setSubscribeNumber(SubscribeNumber + 1);
            // setSubscribed(!Subscribed);
          } else {
            alert("Failed to subscribe");
          }
        });




    } else {
      toast("You have reached to maximum stamps", {
        icon: "👏",
      });
    }
    
  };

  const onUnStampAward = () => {

    if (stampExist + stampCount > 0) {
      setStampCount((prevCount) => prevCount - 1);
      setStampAward(StampAward - 1);
  
      let stampVariables = {
        userTo: userTo,
        userFrom: userFrom,
      };


      socketId.emit("addUser", userFrom);
      socketId.emit("removeStamp", {
        senderId: userFrom,
        senderName: shopName,
        receiverId: userTo,
        stamp:-1,
      });  

  
      axios
        .post(`${URL}/api/stamp/unstampAward`, stampVariables)
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.success);
            // setSubscribeNumber(SubscribeNumber + 1);
            // setSubscribed(!Subscribed);
          } else {
            alert("Failed to remove StampAward");
          }
        });


    } else {
      toast.error("You can not give a negative stamp");
    }
   
  };

  return (
    <>
      <Toaster />
      <div>
        <p>
          Shop{" "}
          <strong style={{ textTransform: "uppercase" }}>
            {props.shopName}{" "}
          </strong>
          will reward Stamps to{" "}
          <strong style={{ textTransform: "uppercase" }}>
            {userInfo.displayName}
          </strong>
        </p>
        <p>
          Recently, customer have{" "}
          <strong style={{ textTransform: "uppercase" }}>
            {StampAwardNumber}
          </strong>{" "}
          stamps{" "}
        </p>
        <div className="data__button">
          <Button
            type="button"
            className="filter-btn1"
            style={{
              fontSize: 20,
              background: "#0c6157",
              color: "white",
              margin: "1rem",
            }}
            onClick={onUnStampAward}
          >
            -
          </Button>
          <span>{stampCount}</span>
          <Button
            type="button"
            className="filter-btn1"
            style={{
              fontSize: 20,
              background: "#0c6157",
              color: "white",
              margin: "1rem",
            }}
            onClick={onStampAward}
          >
            +
          </Button>
        </div>
        <Box
          sx={{
            displpay: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            margin: "0 auto",
          }}
        >
          <Stack
            spacing={1}
            m="0 auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Item>
              <Box
                sx={{
                  background: CardColor,
                  minHeight: 550,
                  borderRadius: "25px",
                }}
              >
                <Stack
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#000",
                    borderRadius: "25px 25px 0 0",
                  }}
                >
                  <Avatar
                    src={StoreAvatar}
                    sx={{ mt: "2rem", width: 56, height: 56 }}
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Bruno Ace",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      color: "#fff",
                    }}
                  >
                    The{" "}
                    <strong style={{ color: CardColor }}>{"Store Name"}</strong>{" "}
                    Store
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      textTransform: "uppercase",
                      color: CardColor,
                    }}
                  >
                    {cardDesc1}
                  </Typography>
                </Stack>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path
                    fill="#000"
                    fill-opacity="1"
                    d="M0,128L60,144C120,160,240,192,360,181.3C480,171,600,117,720,117.3C840,117,960,171,1080,186.7C1200,203,1320,181,1380,170.7L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
                  ></path>
                </svg>

                <Box minHeight={250}>
                  <Stamps
                    stampsselected={NoofStamps}
                    pickImg={PickImg}
                    pickunImg={pickunImg}
                    stampedBackground={StampColor}
                    unstampedBackground={unStampColor}
                    stampCount={stampExist + stampCount}
                  />
                </Box>
                <Box p="1rem">
                  <Typography variant="p">{cardDesc2}</Typography>
                </Box>
              </Box>
            </Item>
            <Item>
              <Box
                sx={{
                  background: CardColor,
                  minHeight: 550,
                  borderRadius: "25px",
                }}
              >
                <div className="decription-section">
                  <p className="decription-section-h4">REWARD DETAILS</p>
                  <p>{cardDesc4}</p>
                </div>
                <div className="decription-section">
                  <p className="decription-section-h4">TERM & CONDITIONS</p>
                  <p>{cardDesc5}</p>
                </div>
                <div className="decription-section">
                  <p className="decription-section-h4">
                    QUESTIONS ABOUT THIS CARD. PLEASE CONTACT
                  </p>
                  <p>{cardDesc3}</p>
                </div>
              </Box>
            </Item>
          </Stack>
        </Box>
        {/* <Stamps
          stampsselected={5}
          pickImg={pickImg}
          pickunImg={pickunImg}
          stampedBackground={colorStamps}
          unstampedBackground={colorunStamps}
          stampCount={stampExist + stampCount}
        /> */}


        {parseInt(StampAward) < 0 ? (
          <p>
            {" "}
            Store have remove{" "}
            <strong style={{ textTransform: "uppercase" }}>
              {-StampAward}
            </strong>{" "}
            stamps to customers
          </p>
        ) : (
          <p>
            Store have added{" "}
            <strong style={{ textTransform: "uppercase" }}>{StampAward}</strong>{" "}
            stamps to customers{" "}
          </p>
        )}

        {/* <p> Customer now have <strong style={{ textTransform: "uppercase" }}>{StampAward+StampAwardNumber}</strong> stamps</p> */}
      </div>
    </>
  );
};

export default LiveReward;
