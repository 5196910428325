import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../LoadingError/Error";
import Toast from "../LoadingError/Toast";
import QrReader from "react-qr-reader";
import { Avatar, Box, Divider, getFormControlLabelUtilityClasses, Typography } from "@mui/material";
import { URL } from "../../Redux/Url";
import axios from "axios";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { toast } from "react-toastify";
import JoinRoyalty from "./JoinRoyalty";

const UserQRInfo = ({ scanId, shopName, shopId, socket }) => {
  // console.log({ scanId });
  // console.log({socket})
  let userInforVariable = { userId: scanId };
  const [userInfo, setUserInfo] = useState([]);
  const [joinRoyaled, setJoinRoyaled] = useState(false);
  const [joinData, setJoinData]=useState([])
  const [Subscribed, setSubscribed] = useState(false);
  const [subcriber, setSubcriber] = useState([]);


  let joinVariables = {
    userTo: shopId,
    userFrom: scanId,
  };

  useEffect(() => {
    axios
      .post(`${URL}/api/v1/user/userInfo`, userInforVariable)
      .then((response) => {
        if (response.data.success) {
          toast.success("successful has User Information");
          // console.log(response.data.user);
          setUserInfo(response.data.user);
        } else {
          alert("Failed to get subscription products");
        }
      });


      axios
        .post(
          `${URL}/api/subscribe/subscribed`,
          joinVariables
        )
        .then((response) => {
          if (response.data.success) {
            
            // console.log("response.data.subcribed", response.data.subcribed);
            // console.log("response.data.subcribe", response.data.subscribe)            ;

            toast.info("successull get subcribed  of the user");
            setSubscribed(response.data.subcribed);
            setSubcriber(response.data.subscribe)
            
          } else {
            alert("Failed to get Subscribed Information");
          }
        });

    //GET JOINED LOYALTY PROGRAM INFORMATION
    axios
      .post(`${URL}/api/royalprogram/joined`, joinVariables)
      .then((response) => {
        if (response.data.success) {
          // console.log(`${scanId} JOINED ROYAL PROGRAM :`, response.data.joined);
          // console.log(`${scanId} JOINED ROYAL PROGRAM :`, response.data.join);
          setJoinRoyaled(response.data.joined);
          setJoinData(response.data.join)
        } else {
          alert("Failed to get Subscribed Information");
        }
      });
  }, []);

  const handleNavigate =(scanId) =>{

    // console.log("Navigate to Loyalty Page",scanId)
    socket.emit("addUser", userInfo.id);
    socket.emit("sendNavigate", {
      senderId: userInfo.id,
      senderName: userInfo.name,
      receiveId: scanId,
      type:"/royalty",
    });

  }


  return (
    <>
      {/* <Toast /> */}
      <div>
        <h4>USER INFORMATION</h4>
        <br />
        {userInfo ? (
          <Box marginLeft="0rem">
            <Stack spacing={1}>
              <Avatar src={userInfo.photo} sizes="large" />

              <Typography>
                Name:{" "}
                <strong style={{ textTransform: "uppercase" }}>
                  {userInfo.displayName}
                </strong>
              </Typography>
              <Typography>
                Email:{" "}
                <strong style={{ textTransform: "uppercase" }}>
                  {userInfo.username}
                </strong>
              </Typography>
              <Typography>
                Date of birth:{" "}
                <strong style={{ textTransform: "capitalize" }}>
                  {moment(userInfo.dateOfBirth).format("LL")}
                </strong>
              </Typography>
              <Typography>
                Gender:{" "}
                <strong style={{ textTransform: "lowercase" }}>
                  {userInfo.gender}
                </strong>
              </Typography>
              <Typography>
                Bio:{" "}
                <strong style={{ textTransform: "lowercase" }}>
                  {userInfo.bio}
                </strong>
              </Typography>
              <Typography>
                Phone:{" "}
                <strong style={{ textTransform: "lowercase" }}>
                  {userInfo.phone}
                </strong>
              </Typography>
              <Typography>
                Follow the shop{" "}
                
                <span style={{ fontWeight: 700 }}>{shopName}</span>
                
                <strong style={{ textTransform: "capitalize" }}> : {Subscribed ? "YES" : "NO"}</strong>
                {" "} since:{" "}
                <strong style={{ textTransform: "capitalize" }}>
                  {Subscribed ? moment(subcriber.createAt).format("LL") : "UNKNOW"}
                </strong>
              </Typography>
              <Typography>
                Joined Royalty Program :{" "}
                <strong style={{ textTransform: "capitalize" }}>{joinRoyaled ? "YES" : "NO"}</strong>
                {" "} since <strong style={{ textTransform: "capitalize" }}>{joinRoyaled ? moment(joinData.createAt).format("LL") : "UNKNOW"}</strong>
              </Typography>

              {/* automatic navigate to royalty page of user */}
              {joinRoyaled ? handleNavigate(scanId): null}

              {!joinRoyaled && <JoinRoyalty userTo={shopId} userFrom={scanId} socket={socket}/>}
            </Stack>
          </Box>
        ) : (
          <p>
            "User information is not found, please ask your customers to
            resigter in Lokalai and join Royalty program"
          </p>
        )}
      </div>
    </>
  );
};

export default UserQRInfo;
