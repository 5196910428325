import axios from "axios";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight, AiOutlineSend } from "react-icons/ai";
import { TfiGallery } from "react-icons/tfi";
import { format } from "timeago.js";
import { URL } from "../../Redux/Url";
import { Link, useHistory } from "react-router-dom";
import { Avatar, Box, Button, Chip, Divider } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SendIcon from "@mui/icons-material/Send";
import CollectionsIcon from "@mui/icons-material/Collections";
import "./chat.css";

import useTyping from "./hooks/useTyping";
import useOutsideClick from "./hooks/useOutsideClick";
import TypingMessage from "./TypingMessage";
import emojiIcon from "../Message/assets/emojiIcon.png";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

const DashboardMessage = ({ socketId }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // console.log({ userInfo });
  // console.log(socketId)
  const seller = userInfo;
  // console.log({seller})
  const [conversations, setConversations] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentChat, setCurrentChat] = useState();
  const [messages, setMessages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);
  const [images, setImages] = useState();
  const [open, setOpen] = useState(false);
  const scrollRef = useRef(null);
  const { isTyping, startTyping, stopTyping, cancelTyping } = useTyping();
  const [typingUsers, setTypingUsers] = useState([]);

  useEffect(() => {
    socketId.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });

    socketId.on("startTypingMessage", (typingInfo) => {
      // console.log("startTypingMessage", typingInfo)
      const user = typingInfo;
      setTypingUsers((users) => [...users, user]);
      // }
    });

    socketId.on("stopTypingMessage", (typingInfo) => {
      // console.log("stopTypingMessage", typingInfo)
      const user = typingInfo;
      setTypingUsers((users) => users.filter((u) => u !== user));
    });
  }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getConversation = async () => {
      try {
        const resonse = await axios.get(
          `${URL}/api/chat/get-all-conversation-seller/${seller._id}`
        );

        setConversations(resonse.data.conversations);
        // console.log({ conversations });
      } catch (error) {
        console.log(error);
      }
    };
    getConversation();
  }, [seller, messages]);

  useEffect(() => {
    if (seller) {
      const sellerId = seller?._id;
      socketId.emit("addUser", sellerId);
      socketId.on("getUsers", (data) => {
        setOnlineUsers(data);
      });
    }
  }, [seller]);

  const onlineCheck = (chat) => {
    const chatMembers = chat.members.find((member) => member !== seller?._id);
    const online = onlineUsers.find((user) => user.userId === chatMembers);

    return online ? true : false;
  };

  // get all messages
  useEffect(() => {
    const getMessage = async () => {
      try {
        const response = await axios.get(
          `${URL}/api/message/get-all-messages/${currentChat?._id}`
        );
        setMessages(response.data.messages);
      } catch (error) {
        console.log(error);
      }
    };
    getMessage();
  }, [currentChat]);

  //listening section

  // listenning an event
  const startTypingMessage = () => {
    console.log("startTypingMessage work");
    if (!currentChat) return;
    const receiverId = currentChat.members.find(
      (member) => member !== seller._id
    );
    console.log({ receiverId });
    // if (!socketId.current) return;
    socketId.emit("startTypingMessage", {
      senderId: seller._id,
      sendername: seller.name,
      receiverId,
    });
  };

  const stopTypingMessage = () => {
    console.log("stopTypingMessage work");
    if (!currentChat) return;
    const receiverId = currentChat.members.find(
      (member) => member !== seller._id
    );

    console.log({ receiverId });
    // if (!socketId) return;
    socketId.emit("stopTypingMessage", {
      senderId: seller._id,
      sendername: seller.name,
      receiverId,
    });
  };
  // const startTypingMessage = () => {
  //   if (!currentChat) return
  //   const receiverId = currentChat.members.find(
  //     (member) => member.id !== seller._id
  //   );
  //   // if (!socketId.current) return;
  //   socketId.emit("startTypingMessage", {
  //     senderId:  seller._id,
  //     receiverId,
  //   });
  // };

  // const stopTypingMessage = () => {
  //   // console.log({currentChat})
  //   if (!currentChat) return
  //   const receiverId = currentChat.members.find(
  //     (member) => member.id !== seller._id
  //   );
  //   // if (!socketId) return;
  //   socketId.emit("stopTypingMessage", {
  //     senderId:  seller._id,
  //     receiverId,
  //   });
  // };

  useEffect(() => {
    if (isTyping) startTypingMessage();
    else stopTypingMessage();
  }, [isTyping]);

  // create a new message
  const sendMessageHandler = async (e) => {
    if (!socketId) return;
    e.preventDefault();
    cancelTyping();
    const message = {
      sender: userInfo._id,
      text: newMessage,
      conversationId: currentChat._id,
    };

    // console.log({ message });

    const receiverId = currentChat.members.find(
      (member) => member.id !== seller._id
    );

    socketId.emit("sendMessage", {
      senderId: seller._id,
      receiverId,
      text: newMessage,
    });

    //send notification from dashboard to socket server
    socketId.emit("sendNotification", {
      senderId: seller._id,
      senderName: seller.name,
      receiverId,
      type: 3,
    });

    try {
      if (newMessage !== "") {
        await axios
          .post(`${URL}/api/message/create-new-message`, message)
          .then((res) => {
            setMessages([...messages, res.data.message]);
            updateLastMessage();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateLastMessage = async () => {
    socketId.emit("updateLastMessage", {
      lastMessage: newMessage,
      lastMessageId: seller._id,
    });

    await axios
      .put(`${URL}/api/chat/update-last-message/${currentChat._id}`, {
        lastMessage: newMessage,
        lastMessageId: seller._id,
      })
      .then((res) => {
        // console.log("res.data.conversation", res.data.conversation);
        setNewMessage("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setImages(file);
    imageSendingHandler(file);
  };

  const imageSendingHandler = async (e) => {
    const formData = new FormData();

    formData.append("images", e);
    formData.append("sender", seller._id);
    formData.append("text", newMessage);
    formData.append("conversationId", currentChat._id);

    const receiverId = currentChat.members.find(
      (member) => member !== seller._id
    );

    socketId.emit("sendMessage", {
      senderId: seller._id,
      receiverId,
      images: e,
    });

    // try {
    //   await axios
    //     .post(`${server}/message/create-new-message`, formData,{
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })
    //     .then((res) => {
    //       setImages();
    //       setMessages([...messages, res.data.message]);
    //       updateLastMessageForImage();
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const updateLastMessageForImage = async () => {
    // await axios.put(
    //   `${server}/conversation/update-last-message/${currentChat._id}`,
    //   {
    //     lastMessage: "Photo",
    //     lastMessageId: seller._id,
    //   }
    // );
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ beahaviour: "smooth" });
  }, [messages]);

  return (
    <div className="w-[100%] bg-white m-2 h-[100vh] overflow-y-scroll rounded">
      {!open && (
        <>
          <h1 className="text-center text-[30px] py-3 font-Poppins">
            All Messages
          </h1>
          {/* All messages list */}
          {conversations &&
            conversations.map((item, index) => (
              <MessageList
                data={item}
                key={index}
                index={index}
                setOpen={setOpen}
                setCurrentChat={setCurrentChat}
                me={seller._id}
                setUserData={setUserData}
                userData={userData}
                online={onlineCheck(item)}
                setActiveStatus={setActiveStatus}
              />
            ))}
        </>
      )}

      {open && (
        <SellerInbox
          setOpen={setOpen}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          sendMessageHandler={sendMessageHandler}
          messages={messages}
          sellerId={seller._id}
          userData={userData}
          activeStatus={activeStatus}
          scrollRef={scrollRef}
          setMessages={setMessages}
          handleImageUpload={handleImageUpload}
          handleStartTyping={startTyping}
          handleStopTyping={stopTyping}
          typingUsers={typingUsers} //listening events
        />
      )}
    </div>
  );
};

const MessageList = ({
  data,
  index,
  setOpen,
  setCurrentChat,
  me,
  setUserData,
  online,
  setActiveStatus,
}) => {
  // console.log(data);
  const [user, setUser] = useState([]);
  const history = useHistory();
  const handleClick = (id) => {
    // navigate(`/dashboard-messages?${id}`);
    // history.push(`/dashboard-messages?${id}`);
    setOpen(true);
  };
  const [active, setActive] = useState(0);

  useEffect(() => {
    const userId = data.members.find((user) => user != me);

    // console.log({ userId });
    let variable = { userId: userId };
    const getUser = async () => {
      try {
        const res = await axios.post(`${URL}/api/v1/user/userInfo`, variable);
        // console.log(res.data.user );
        setUser(res.data.user);
        setUserData(res.data.user);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, [me, data]);

  return (
    <Box
      display="flex"
      margin={3}
      onClick={(e) =>
        setActive(index) ||
        handleClick(data._id) ||
        setCurrentChat(data) ||
        setUserData(user) ||
        setActiveStatus(online)
      }
      sx={{
        bgcolor: `${active === index ? "lightgray" : "transparent"}`,
        cursor: "pointer",
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Box position="relative">
        <Avatar src={user.photo}></Avatar>
        {online ? (
          <Box
            position="absolute"
            width={12}
            height={12}
            top={-2}
            right={-2}
            sx={{ bgcolor: "#66BB6A", borderRadius: "50%" }}
          ></Box>
        ) : (
          <Box
            position="absolute"
            width={12}
            height={12}
            top={-2}
            right={-2}
            sx={{ bgcolor: "#808080", borderRadius: "50%" }}
          ></Box>
        )}
      </Box>
      <Box marginLeft={2}>
        <h6 className="text-[18px]">{user.displayName}</h6>
        <small className="text-[16px] text-[#000c]">
          {data?.lastMessageId !== user?.id ? "You: " : user.displayName}
          {data?.lastMessage}
        </small>
      </Box>
    </Box>
  );
};

const SellerInbox = ({
  scrollRef,
  setOpen,
  newMessage,
  setNewMessage,
  sendMessageHandler,
  messages,
  sellerId,
  userData,
  activeStatus,
  handleImageUpload,
  handleStartTyping,
  handleStopTyping,
  typingUsers,
}) => {
  const { showEmoji, setShowEmoji, ref } = useOutsideClick(false);
  const handleEmojiShow = () => {
    setShowEmoji((v) => !v);
  };
  const handleEmojiSelect = (e) => {
    setNewMessage((newMessage) => (newMessage += e.native));
  };
  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ minHeight: "50vh" }}
    >
      {/* Header Conversation */}
      {/* {console.log({ userData })} */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: 1,
          borderRadius: 2,
          backgroundColor: "rgb(226,232,240)",
        }}
      >
        <Box display="flex">
          <Avatar src={userData.photo}></Avatar>
          <Box marginLeft={2}>
            <h6>{userData.displayName}</h6>
            <small>Active now</small>
          </Box>
        </Box>
        <ArrowForwardIcon
          onClick={() => setOpen(false)}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      {/* Chat window */}

      <div
        className="message-area1"
        style={{ background: "rgb(248, 250, 252)" }}
      >
        {/* {console.log({ messages })} */}
        {messages &&
          messages.map((item, index) => {
            return (
              <Box
                display={"flex"}
                m={0}
                sx={{
                  justifyContent: `${
                    item.sender === sellerId ? "end" : "start"
                  }`,
                }}
                ref={scrollRef}
              >
                {item.sender !== sellerId && (
                  <Avatar src={`${userData?.photo}`} alt="" />
                )}
                {/* {
            item.images && (
              <img
                 src={`${backend_url}${item.images}`}
                 className="w-[300px] h-[300px] object-cover rounded-[10px] mr-2"
              />
            )
          } */}
                {item.text !== "" && (
                  <div>
                    <Box
                      p={1}
                      sx={{
                        backgroundColor: `${
                          item.sender === sellerId ? "#38c776" : "#000"
                        }`,
                        borderRadius: "5px",
                        color: "#fff",
                      }}
                    >
                      <p>{item.text}</p>
                    </Box>

                    <p
                      style={{
                        fontSize: "12px",
                        color: "#000000d3",
                        paddingTop: "3px",
                      }}
                    >
                      {format(item.createdAt)}
                    </p>
                  </div>
                )}
              </Box>
            );
          })}
        {/* {console.log({typingUsers})} */}
        {typingUsers.map((user, i) => (
          <div key={messages.length + i}>
            <TypingMessage user={user}></TypingMessage>
          </div>
        ))}
      </div>

      {/* send message */}
      <Box
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: "rgb(226,232,240)",
        }}
      >
        <form className="message-control" onSubmit={sendMessageHandler}>
          <CollectionsIcon size="large" />
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type something...!"
            onKeyPress={handleStartTyping}
            onKeyUp={handleStopTyping}
          />
          <div className="file-input-container">
            <label htmlFor="hidden-file">
              <img
                width="20"
                src="https://images.pexels.com/photos/17202950/pexels-photo-17202950/free-photo-of-city-people-street-building.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt={""}
              />
            </label>
          </div>
          <button
            className="sendButton"
            type="button"
            onClick={handleEmojiShow}
          >
            <img className="uploadImage" src={emojiIcon} alt="uploadImage" />
          </button>

          <button>
            <SendIcon />
            <span style={{ display: "inline-block" }}>Send</span>
          </button>
        </form>
      </Box>
      <div>
        {showEmoji && (
          <div ref={ref}>
            <Picker onSelect={handleEmojiSelect} emojiSize={20} />
          </div>
        )}
      </div>
    </Box>
  );
};

export default DashboardMessage;
