import React, { useState, useRef } from "react";
import Dropzone from "react-dropzone";
import { Icon } from "antd";
import axios from "axios";
import { URL } from "../../Redux/Url";
import "./dropzone.css";
import { MdAddToPhotos } from "react-icons/md";
import { toast } from "react-toastify";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";

const VideoUploadS3 = (props) => {
  const [Videos, setVideos] = useState([]);
  const [Duration, setDuration] = useState("");
  const [Thumbnail, setThumbnail] = useState([]);

  var minutes = Math.floor(Duration / 60);
  var seconds = Math.floor(Duration - minutes * 60);

  const videoEl = useRef(null);

  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    if (!video) return;
    console.log(`The video is ${video.duration} seconds long.`);
    setDuration(video.duration);
  };

  const onDrop = (files) => {
    let formData = new FormData();
    const config = {
      header: { "content-type": "multipart/form-data" },
    };
    console.log(files);
    formData.append("file", files[0]);

    /// ---------- uploadVideo router --------------------------------------------
    axios
      .post(`${URL}/api/products/uploadVideoS3`, formData, config)
      //-----------------------------------------------------------------------
      .then((response) => {
        if (response.data.success) {
          console.log("video :", response);
          toast.success("Successfull upload video to Amazon S3");
          let variable = {
            filePath: response.data.filePath,
            fileName: response.data.fileName,
          };
          setVideos([...Videos, response.data.filePath]);
          props.refreshFunction([...Videos, response.data.filePath]);
          //   props.refreshFunction([...Images, response.data.location]);
          toast.success("Successfull uploaded pictures...lokalai operator");
        } else {
          alert("failed to save the video in server...Dr. Man Ha");
        }
      });
  };

  const onDelete = (video) => {
    const currentIndex = Videos.indexOf(video);

    let newVideos = [...Videos];
    newVideos.splice(currentIndex, 1);

    setVideos(newVideos);
    props.refreshFunction(newVideos);
  };
  return (
    <div
      style={{
        display: "flex",
        alignContent: "flex-end",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Dropzone onDrop={onDrop} multiple={false} maxSize={800000000}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} className="text-center" />
            {/* <Icon type="plus" style={{ fontSize: '3rem' }} /> */}
            <AiOutlineVideoCameraAdd
              style={{ fontSize: 20, marginRight: 5 }}
            />{" "}
            Add video
            <p className="dropzone-content">Release to drop the files here</p>
          </div>
        )}
      </Dropzone>

     <div
className="grid-photos"      >
        {Videos.map((video, index) => (
          <div
            onClick={() => onDelete(video)}
            className="file-list"
            key={index}
          >
            <div>
              <video
                autoPlay={false}
                muted
                onLoadedMetadata={handleLoadedMetadata}
                ref={videoEl}
                className="filevideo"
                width={100}
              >
                <source src={`${video}`}></source>
              </video>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoUploadS3;
