import React, { useEffect, useState } from "react";
import { Button, ButtonLink, Heading, TextWrapper } from "./globalStyles";
import {
  PricingCardInfo,
  PricingCardPlan,
  PricingCardCost,
  PricingCardFeatures,
  PricingCardText,
  PricingCardFeature,
} from "./PricingStyles";
import Tilt from "react-tilt";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

const PricePackageItem = (props) => {
  const [qty, setQty] = useState(1);
  // console.log({ props });
  const { price } = props;
  // console.log({ price });

  const AddToCartHandle = (e) => {
    e.preventDefault();
    // history.push(`/cart/${price._id}?qty=${qty}`);
    <Link to={`/cart/${price._id}?qty=${qty}`} />;
  };

  return (
    <>
      <Tilt className="Tilt" options={{ max: 15, scale: 1.01, speed: 200 }}>
        <PricingCardInfo>
          <PricingCardPlan>{price.title}</PricingCardPlan>
          <PricingCardCost>{price.priceDesc}</PricingCardCost>
          <PricingCardText>{price.numAcc}</PricingCardText>
          <PricingCardFeatures>
            {price.features.map((feature, index) => (
              <PricingCardFeature key={index}>{feature}</PricingCardFeature>
            ))}
          </PricingCardFeatures>
          <Link to={`/cart/${price._id}?qty=${qty}`}>
            <Button>Add To Cart</Button>
          </Link>
        </PricingCardInfo>
      </Tilt>
    </>
  );
};

export default PricePackageItem;
