import React from "react";
import "./Loading4.css";

const Loading = () => {
  return (
    <section class="sec-loading">
      <div class="one"></div>
      <div class="container">
        {/* <div class="yellow"></div>
        <div class="yellow"></div>

        <div class="yellow"></div>
        <div class="yellow"></div>
        <div class="red"></div> */}
        {/* <div class="blue"></div>
	<div class="violet"></div> */}
      </div>
      <h1 style={{ marginTop: "2rem" }}>Admin loading ... </h1>
    </section>
  );
};

export default Loading;
