const dataStample = [
  {
    value: 5,
    label: "5 stamps",
  },
  {
    value: 6,
    label: "6 stamps",
  },
  {
    value: 7,
    label: "7 stamps",
  },
  {
    value: 8,
    label: "8 stamps",
  },
  // {
  //   value: 91,
  //   label: "9 stamps type 1",
  // },
  {
    value: 9,
    label: "9 stamps type 2",
  },
  {
    value: 10,
    label: "10 stamps",
  },
  {
    value: 12,
    label: "12 stamps",
  },
  {
    value: 14,
    label: "14 stamps",
  },
  {
    value: 15,
    label: "15 stamps",
  },
  {
    value: 16,
    label: "16 stamps",
  },
  {
    value: 20,
    label: "20 stamps",
  },
];

export default dataStample;
