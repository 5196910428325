import React from 'react';
import { MdLoyalty } from 'react-icons/md';
import { AiOutlineIdcard } from 'react-icons/ai';
import { BiFoodMenu } from 'react-icons/bi';
import { MdPayments } from 'react-icons/md';
import { TbBrandBooking } from 'react-icons/tb';
import { TbShoppingCartDiscount } from 'react-icons/tb';

const iconStyle = (Icon) => <Icon size="3rem" color="#fefefe" />;

export const featuresData = [
	{
		name: 'Reward Card',
		description:
			'Custom design Reward Card',
		icon: iconStyle(MdLoyalty),
		imgClass: 'one',
	},
	{
		name: 'AI control',
		description: 'Use AI to select the best deals',
		icon: iconStyle(AiOutlineIdcard),
		imgClass: 'two',
	},
	{
		name: 'Online Menu',
		description: 'Custom Design online Menu ',
		icon: iconStyle(BiFoodMenu),
		imgClass: 'three',
	},
	{
		name: 'Crypto Payment',
		description: 'Accepte Crypto Currency with other Fiat Currency',
		icon: iconStyle(MdPayments),
		imgClass: 'four',
	},
	{
		name: 'Booking',
		description: 'Online reserve tables or deals',
		icon: iconStyle(TbBrandBooking),
		imgClass: 'five',
	},
	{
		name: 'Best Deals',
		description: 'Highest Discount',
		icon: iconStyle(TbShoppingCartDiscount),
		imgClass: 'six',
	},
];
