import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../LoadingError/Error";
import Toast from "../LoadingError/Toast";
import Loading from "../LoadingError/Loading";
import { toast } from "react-toastify";
import { updateUserProfile } from "../../Redux/Actions/userActions";
import { FrontEnd_URL } from "../../Redux/Url";
import QRcode from "qrcode";

const QROperatorCode = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [bio, setBio] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toastId = React.useRef(null);

  const Toastobjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
  };

  const dispatch = useDispatch();

  // const userDetails = useSelector((state) => state.userDetails);
  // const { loading, error, user } = userDetails;
  const [srcOperator, setSrcOperator] = useState("");

  // console.log({ user });

 

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const { _id} = userInfo

  // useEffect(() => {
  //   if (user) {
  //     setName(user.name);
  //     setEmail(user.email);
  //     setDob(user.dateOfBirth);
  //     setGender(user.gender);
  //     setBio(user.bio);
  //     setPhone(user.phone);
  //   }
  // }, [dispatch, user]);

  const submitHandler = (e) => {
    toast.success("Welcome to Print QR Image");
  };

  useEffect(() => {
    const FrontEnd_URL_QR = `${FrontEnd_URL}/operator/${_id}`;
    console.log({FrontEnd_URL_QR})    
    QRcode.toDataURL(FrontEnd_URL_QR).then(setSrcOperator);
  }, []);

  return (
    <>
      <Toast />
      {error && <Message variant="alert-danger">{error}</Message>}
      {loading && <Loading />}
      <form
        className="row  form-container"
        onSubmit={submitHandler}
        style={{ background: "#fff" }}
      >
        {srcOperator !=="" && <img src={srcOperator} style={{maxWidth: "400px", margin:"0 auto"}}/>}
        

        <button type="submit">Print</button>
      </form>
    </>
  );
};

export default QROperatorCode;
