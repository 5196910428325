import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  HeroSection,
  Heading,
  HeroText,
  ButtonContainer,
  HeroButton,
  SignUpButton,
  SignInButton,
  ImageCharacter,
  HeroImage,
  HeroContent,
  ButtonWrapper,
  CharacterContainer,
} from "./HeroStyles";

import { Button } from '../globalStyles';


const Hero = () => {
  return (
    <>
      <HeroSection id="hero">
        <HeroImage className="pattern" src="./images/hero-pattern-bg-lg.png" />
        <HeroImage className="guy" src="./images/hero-guy-1.png" />
        <HeroContent>
          <Heading>Lokalai Admin Dashboard</Heading>
          <HeroText>
          Lokalai is an innovative IT software service company that aims to revolutionise the way retail shops and their customers interact.
          </HeroText>
            <ButtonWrapper>
              <Link to="/signup">
                <Button>Sign Up Free</Button>
              </Link>
              <Link to="/signin">
              <HeroButton>Sign In</HeroButton>
              </Link>
            </ButtonWrapper>
        </HeroContent>
      </HeroSection>
      {/* <Modal showModal={showModal} toggleModal={toggleModal} /> */}
    </>
  );
};

export default Hero;
