import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Select from "react-select";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import dataStamps from "./dataStamped";
import pictureStamps from "./section/picture";
import Stamps from "./section/stamps";
import { HexColorPicker } from "react-colorful";
import "./RewardLoyalty.css";
import ReactCircleColorPicker from "react-circle-color-picker";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import coffee from "../../assets/stampIcon/coffee.PNG";
import coffee2 from "../../assets/svg/coffee-2.svg";

import toast, { Toaster } from "react-hot-toast";
import {URL} from "../../Redux/Url"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  maxWidth: 370,
  minWidth: 370,
}));

//BEGIN OF CARD DESIGN SECTION
const RewardDesignPage = (props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // console.log({ userInfo });

  const [value, setValue] = useState("description");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(value);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const StoreAvatar = userInfo.photo;
  const StoreName = userInfo.name;
  const StoreCover = userInfo.cover;
  const [colorCard, setColorCard] = useState("#FFC70B");

  const [colorStamps, setColorStamp] = useState("#fff");
  const [colorunStamps, setColorunStamp] = useState("#fff");

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showColorPicker1, setShowColorPicker1] = useState(false);
  const [showColorPicker2, setShowColorPicker2] = useState(false);

  const [design, setDesign] = useState(false);
  const [detail, setDetail] = useState(false);
  const [support, setSupport] = useState(false);
  const [data, setData] = useState(false);
  const [submit, setSubmit] = useState(true);

  const [stamps, setStamps] = useState(5);
  const [pickImg, setPickImg] = useState(coffee);
  const [pickunImg, setPickunImg] = useState();

  const [cardDesc1, setCardDesc1] = useState("Brunch cafe");
  const [cardDesc2, setCardDesc2] = useState(
    "For every single-receipt purchase of $20, get a stamp to collect rewards!"
  );
  const [cardDesc3, setCardDesc3] = useState("Dr.man Store");
  const [cardDesc4, setCardDesc4] = useState(
    "Get $10 off your next purchase after collecting all stamps."
  );

  // Support section
  const [cardDesc5, setCardDesc5] = useState(
    "1. Get 1 stamp for every $5 spent.  2. Collect all stamps to receive $10 off your next purchase. 3. Reward can only be redeemed in full. Partial use of a reward is not permitted. 4. Stamps and rewards cannot be exchanged, refunded, replaced or redeemed for cash. 5. We reserve the right of final decisions in case of any disputes."
  );

  const [src, setSrc] = useState("");

  //Stamp section
  const [stampCount, setStampCount] = useState(1);
  const decrease = () => {
    if (stampCount > 0) {
      setStampCount((prevCount) => prevCount - 1);
    } else {
      toast.error("You can not give a negative stamp");
    }
  };

  const increase = () => {
    if (stamps === 91 || stamps === 92) {
      setStamps(9);
    }
    if (stampCount < stamps) {
      setStampCount((prevCount) => prevCount + 1);
    } else {
      toast("You have reached to maximum stamps", {
        icon: "👏",
      });
    }
  };

  const [colorsArray, setcolorsArray] = React.useState([
    { hex: "#B2664E", selected: false },
    { hex: "#EF62A2", selected: false },
    { hex: "#9867B6", selected: false },
    { hex: "#1566C1", selected: false },
    { hex: "#01685B", selected: false },
    { hex: "#F99C53", selected: false },
    { hex: "#C09C8F", selected: false },
    { hex: "#FF5B5B", selected: false },
    { hex: "#D1A2DA", selected: false },
    { hex: "#5B9DDD", selected: false },
    { hex: "#42A047", selected: false },
    { hex: "#FFD540", selected: false },
    { hex: "#958B88", selected: false },
    { hex: "#FF899F", selected: false },
    { hex: "#EBC9F2", selected: false },
    { hex: "#93D7FD", selected: false },
    { hex: "#9ADB4C", selected: false },
    { hex: "#E3EC4F", selected: false },
    { hex: "#FFC70B", selected: false },
    { hex: "#f44336", selected: false },
  ]);

  const [CategoryColor, setCategoryColor] = React.useState("");
  const ChangeSelectedColor = (color, previousColor) => {
    let colorHexacode12 = color.filter((e) => e.selected == true)[0];
    if (colorHexacode12 == undefined) {
      setcolorsArray(color);
      setCategoryColor("");
    } else {
      if (previousColor != "") {
        color.filter((e) => e.hex == previousColor)[0].selected = false;
        setcolorsArray(color);
      }
      let colorHexacode = color.filter((e) => e.selected == true)[0].hex;
      setColorCard(colorHexacode);
      if (previousColor == colorHexacode) {
        color.filter((e) => e.hex == previousColor)[0].selected = false;
        setcolorsArray(color);
        setCategoryColor("");
      } else {
        if (previousColor != "") {
          color.filter((e) => e.hex == previousColor)[0].selected = false;
          setcolorsArray(color);
        }
        setCategoryColor(colorHexacode);
      }
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    // if (!TitleValue || !DescriptionValue || !PriceValue ||
    //     !ContinentValue || !Images || !prediction) {
    //     return alert('fill all the fields first!')
    // }

    console.log({userInfo})

    const variables = {
      writer: userInfo._id,
      CardColor: colorCard,
      StampColor: colorStamps,
      unStampColor: colorunStamps,
      NoOfStamp: stampCount,
      PickImg: pickImg,
      PickUnImg: pickunImg,
      cardDesc1: cardDesc1,
      cardDesc2: cardDesc2,
      cardDesc3: cardDesc3,
      cardDesc4: cardDesc4,
      cardDesc5: cardDesc5,
      NoofStamps: stamps,
    };

    

    console.log("Card Design submited infor :", variables);
    Axios.post(`${URL}/api/royalprogram/uploadStamp`, variables).then((response) => {
      if (response.data.success) {
        toast.success("Dr. Man Ha Card Design Infomartion Successfully Uploaded");
        // props.history.push("/store/loyalty/design");

        // props.history.push('/store/loyalty/design')
        // props.history.push("/");
      } else {
        toast.error("Failed to upload Card Design Infomartion");
      }
    });
  };

  return (
    <>
      <Toaster />
      <Box width="100%" m="80px auto">
        <Box display="flex" flexWrap="wrap" columnGap="40px">
          {/* LEFT SECTION - DESIGN FACTORS */}
          <Box flex="1 1 40%" mb="0px">            
           <Box m="0 auto">
              <Box
                m={{ xs: "0 auto" }}
                sx={{
                  flexGrow: 1,
                  maxWidth: { xs: 370, sm: 800 },
                  bgcolor: "background.paper",
                  // border:"1px solid green"
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  // centered
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                    color: "#000",
                  }}
                >
                  <Tab label="Design" {...a11yProps(0)} />
                  <Tab label="Detail" {...a11yProps(1)} />
                  <Tab label="Support" {...a11yProps(2)} />
                  <Tab label="Stamps" {...a11yProps(3)} />
                  <Tab label="Submit" {...a11yProps(4)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box
                    // border="1px solid red"
                    maxWidth={{ sm: "390px", md: "800px" }}
                  >
                    <Typography
                      align="left"
                      variant="overline"
                      fontWeight={700}
                    >
                      Card background
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "start",
                        alignItems: "center",
                        margin: "1rem",
                      }}
                      flexDirection={{xs: "column", md:"row"}}
                    >
                      <Button
                        variant="text"
                        sx={{ color: "#000", border: "1px solid #000" }}
                        onClick={() =>
                          setShowColorPicker(
                            (showColorPicker) => !showColorPicker
                          )
                        }
                      >
                        {showColorPicker ? "Close" : "Pick a color"}
                      </Button>
                      {showColorPicker && (
                        <Box maxWidth="40rem">
                          <Box>
                          <HexColorPicker
                            color={colorCard}
                            onChange={setColorCard}
                          />
                          </Box>
                          <Box maxWidth="10rem">
                          <ReactCircleColorPicker
                             colors={colorsArray}
                            onChange={(color) =>
                              ChangeSelectedColor(color, CategoryColor)
                            }
                          />
                          </Box>
                        </Box>
                      )}
                      <div
                        className="value"
                        style={{ borderLeftColor: colorCard }}
                      >
                        Current background color is {colorCard}
                      </div>
                    </Box>
                    <Typography
                      align="left"
                      variant="overline"
                      fontWeight={700}
                    >
                      Number of selected stamps
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "start",
                        alignItems: "center",
                        margin: "1rem",
                      }}
                    >
                      <Select
                        options={dataStamps}
                        placeholder="No. of Stamps"
                        onChange={(e) => {
                          const selectedStamp = e.value;
                          setStamps(selectedStamp);
                        }}
                        label="Stamps"
                      ></Select>
                      {stamps} stamps
                    </Box>
                    <Typography
                      align="left"
                      variant="overline"
                      fontWeight={700}
                    >
                      Stamp Icons
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "start",
                        alignItems: "center",
                        margin: "1rem",
                      }}
                    >
                      <Select
                        options={pictureStamps}
                        onChange={(e) => {
                          const selectedPick = e.value;
                          setPickImg(selectedPick);
                        }}
                      />
                      <img src={pickImg} style={{ width: "10%" }} />
                    </Box>
                    <Typography
                      align="left"
                      variant="overline"
                      fontWeight={700}
                    >
                      Stamp's background
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "start",
                        alignItems: "center",
                        margin: "1rem",
                      }}
                    >
                      <Button
                        variant="text"
                        sx={{ color: "#000", border: "1px solid #000" }}
                        onClick={() =>
                          setShowColorPicker1(
                            (showColorPicker1) => !showColorPicker1
                          )
                        }
                      >
                        {showColorPicker1 ? "Close" : "Pick a color"}
                      </Button>
                      {showColorPicker1 && (
                        <HexColorPicker
                          color={colorStamps}
                          onChange={setColorStamp}
                        />
                      )}
                      <div
                        className="value"
                        style={{ borderLeftColor: colorStamps }}
                      >
                        Current Stamp color is {colorStamps}
                      </div>
                    </Box>
                    {/* <div className="background-button">
                      <h4>Select un-Stamped Image</h4>
                      <Select
                        value={pictureStamps}
                        onChange={(e) => {
                          const selectedPick = e.value;
                          setPickunImg(selectedPick);
                        }}
                      />
                      <img src={pickunImg} style={{ width: "10%" }} />
                    </div>
                    <div className="background-button">
                      <h4>Select un-Stamped background</h4>
                      <button
                        onClick={() =>
                          setShowColorPicker2(
                            (showColorPicker2) => !showColorPicker2
                          )
                        }
                      >
                        {showColorPicker2
                          ? "Close color picker"
                          : "Pick a color"}
                      </button>
                      {showColorPicker2 && (
                        <HexColorPicker
                          color={colorunStamps}
                          onChange={setColorunStamp}
                        />
                      )}
                      <div
                        className="value"
                        style={{ borderLeftColor: colorunStamps }}
                      >
                        Current unStamp background color is {colorunStamps}
                      </div>
                    </div> */}
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box maxWidth={500}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={2}
                    >
                      <Typography variant="body1">Card description</Typography>
                      <TextField
                        type="text"
                        multiline
                        size="small"
                        row={2}
                        placeholder="Loyalty Rewards Card"
                        helperText="Please enter your Card description"
                        onChange={(e) => {
                          setCardDesc1(e.target.value);
                        }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={2}
                    >
                      <Typography variant="body1">
                        How to collect stamps
                      </Typography>
                      <TextField
                        type="text"
                        row={2}
                        size="small"
                        multiline
                        helperText="Please enter How to collect stamps"
                        placeholder="Get 1 stamp for every $10 spent in store."
                        onChange={(e) => {
                          setCardDesc2(e.target.value);
                        }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={2}
                    >
                      <Typography variant="body1">Business Name</Typography>
                      <TextField
                        type="text"
                        size="small"
                        multiline
                        helperText="Please enter your Business Name"
                        placeholder={StoreName}
                        onChange={(e) => {
                          setCardDesc3(e.target.value);
                        }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={2}
                    >
                      <Typography variant="body1">Reward Detail</Typography>
                      <TextField
                        helperText="Please enter your Reward Detail"
                        type="text"
                        size="small"
                        multiline
                        placeholder="$10 off"
                        onChange={(e) => {
                          setCardDesc4(e.target.value);
                        }}
                      />
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box maxWidth={500}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      justifyContent="space-between"
                      mb={2}
                    >
                      <Typography variant="body1">
                        Terms & Conditions
                      </Typography>
                      <TextField
                        helperText="Please enter your Terms & Conditions"
                        minRows={4}
                        multiline
                        placeholder={cardDesc5}
                        onChange={(e) => {
                          setCardDesc5(e.target.value);
                        }}
                        fullWidth
                        sx={{ marginTop: "2rem" }}
                      />
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box
                    maxWidth={500}
                    padding={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body1" m={1}>
                      Click <strong>- or +</strong> to give stamps to your
                      customers
                    </Typography>
                    <Box m="1rem auto">
                      <Button
                        variant="contained"
                        onClick={decrease}
                        sx={{ fontWeight: 700 }}
                      >
                        -
                      </Button>
                      <strong style={{ margin: "1rem" }}>{stampCount}</strong>
                      <Button
                        variant="contained"
                        onClick={increase}
                        sx={{ fontWeight: 700 }}
                      >
                        +
                      </Button>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <Button
                    helperText="Please enter your Terms & Conditions"
                    variant="contained"
                    onClick={onSubmit}
                    sx={{margin:"0 auto"}}
                    fullWidth
                  >
                    Submit to server
                  </Button>
                </TabPanel>
              </Box>
            </Box>
          </Box>
          {/* RIGHT SECTION - CUSTOMER VIEW */}
          <Box flex="1 1 50%" mb="0px" alignItems="start" >
          <Box
              sx={{
                displpay: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                margin: "0 auto",
                
              }}
            >
              <Stack
                spacing={1}
                m="0 auto"
                display="flex"
                flexDirection="column"
                alignItems="center"
                
              >
                <Item >
                  <Typography
                    variant="h5"
                    textTransform="uppercases"
                    fontWeight={700}
                  >
                    Customers' views
                  </Typography>
                </Item>
                <Item>
                  <Box
                    sx={{
                      background: colorCard,
                      minHeight: 550,
                      borderRadius: "25px",
                    }}
                  >
                    <Stack
                      spacing={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        background: "#000",
                        borderRadius: "25px 25px 0 0",
                      }}
                    >
                      <Avatar
                        src={StoreAvatar}
                        sx={{ mt: "2rem", width: 56, height: 56 }}
                      />
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Bruno Ace",
                          fontWeight: 500,
                          textTransform: "uppercase",
                          color: "#fff",
                        }}
                      >
                        The{" "}
                        <strong style={{ color: colorCard }}>
                          {StoreName}
                        </strong>{" "}
                        Store
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          textTransform: "uppercase",
                          color: colorCard,
                        }}
                      >
                        {cardDesc1}
                      </Typography>
                    </Stack>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1440 320"
                    >
                      <path
                        fill="#000"
                        fill-opacity="1"
                        d="M0,128L60,144C120,160,240,192,360,181.3C480,171,600,117,720,117.3C840,117,960,171,1080,186.7C1200,203,1320,181,1380,170.7L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
                      ></path>
                    </svg>

                    <Box minHeight={250}>
                      <Stamps
                        stampsselected={stamps}
                        pickImg={pickImg}
                        pickunImg={pickunImg}
                        stampedBackground={colorStamps}
                        unstampedBackground={colorunStamps}
                        stampCount={stampCount}
                      />
                    </Box>
                    <Box p="1rem">
                      <Typography variant="p">{cardDesc2}</Typography>
                    </Box>
                  </Box>
                </Item>
                <Item>
                  <Box
                    sx={{
                      background: colorCard,
                      minHeight: 550,
                      borderRadius: "25px",
                    }}
                  >
                    <div className="decription-section">
                      <p className="decription-section-h4">REWARD DETAILS</p>
                      <p>{cardDesc4}</p>
                    </div>
                    <div className="decription-section">
                      <p className="decription-section-h4">TERM & CONDITIONS</p>
                      <p>{cardDesc5}</p>
                    </div>
                    <div className="decription-section">
                      <p className="decription-section-h4">
                        QUESTIONS ABOUT THIS CARD. PLEASE CONTACT
                      </p>
                      <p>{cardDesc3}</p>
                    </div>
                  </Box>
                </Item>
              </Stack>
            </Box>
            
            
            
 
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RewardDesignPage;
