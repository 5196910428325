export const navbarData = [
	{
		to: 'about',
		text: 'About',
	},
	{
		to: 'clients',
		text: 'Clients',
	},
	{
		to: 'projects',
		text: 'Projects',
	},
];
