import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Icon } from "antd";
import Axios from "axios";
import { URL } from "../../Redux/Url";
import "./dropzone.css";
import { MdAddToPhotos } from "react-icons/md";
import { toast } from "react-toastify";

function ImageUploadS3(props) {
  const [Images, setImages] = useState([]);

  const onDrop = (files) => {
    let formData = new FormData();
    const config = {
      header: { "content-type": "multipart/form-data" },
    };
    formData.append("file", files[0]);
    Axios.post(`${URL}/api/products/uploadImageS3`, formData, config)
      //   console.log(formData)
      //   console.log(config)
      //   console.log("file",files[0])
      .then((response) => {
        if (response.data.success) {
          setImages([...Images, response.data.location]);
          props.refreshFunction([...Images, response.data.location]);
          toast.success("Successfull uploaded pictures...lokalai operator");
          console.log(response.data.location);
          console.log({ Images });
        } else {
          toast.error("Failed to save the Image in Server");
        }
      });
  };

  const onDelete = (image) => {
    const currentIndex = Images.indexOf(image);

    let newImages = [...Images];
    newImages.splice(currentIndex, 1);

    setImages(newImages);
    props.refreshFunction(newImages);
  };

  return (
    <div
      style={{
        display: "flex",
        alignContent: "flex-end",
        flexDirection:"column",
        position: "relative",
      }}
    >
      <Dropzone onDrop={onDrop} multiple={false} maxSize={800000000}>
        {({ getRootProps, getInputProps }) => (
          <div
            className="input-zone"
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} className="text-center" />
            <MdAddToPhotos style={{ fontSize: "20px" }} /> Add photos

            <p className="dropzone-content">Release to drop the files here</p>
          </div>
        )}
      </Dropzone>
      <div
className="grid-photos"
      >
        {Images.map((image, index) => (
          <div onClick={() => onDelete(image)} className="file-list">
            <img
              src={image}
              alt={`productImg-${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageUploadS3;
