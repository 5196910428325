import React, { useEffect, useState } from "react";

import Footer from "./IntroduceSubSections/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import { register } from "../Redux/Actions/userActions";
import Header from "./../components/Header";

import backgroundImage from "./Style/images/3.jpeg";
import Toast from "../components/LoadingError/Toast";
import { ToastContainer, toast } from "react-toastify";
import { GoogleAuth_URL } from "../Redux/Url";


const SignUpScreen = ({ location, history }) => {
  window.scrollTo(0, 0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading, userInfo } = userRegister;
  // console.log({error})

  useEffect(() => {
    if (userInfo) {
      toast.success("Register is successful");
      history.push(redirect);
    }
  }, [userInfo, history, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(register(name, email, password));
  };

  const google = () => {
    window.open(`${GoogleAuth_URL}auth/google`, "_self");
  };

  return (
    <>
      <div
        class="container d-flex justify-content-center align-items-center min-vh-100 min-vw-100"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        
        {loading && <Loading />}
        {!loading && 
        <div
          class="row rounded-5 p-3 shadow box-area"
          style={{
            background: "rgba(255,255,0,0.5",
            backdropFilter: "sepia(90%)",
          }}
        >
          <div
            class="col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column left-box"
            style={{
              background:
                "linear-gradient(to bottom right, #FFC70B, orange, #f44336)",
            }}
          >
            <div class="featured-image mb-3">
              <img
                src="images/login.png"
                class="img-fluid"
                style={{ width: "350px" }}
              />
            </div>
            <img
              src="./images/lokalai5.png"
              width={100}
              style={{ margin: "1rem" }}
            />

            <small
              class="text-white text-wrap text-center"
              style={{ width: "17rem", fontSize: "1.2rem", fontWeight: "600" }}
            >
              Unlock the Power of AI to superchage local businesses
            </small>
          </div>

          <div class="col-md-6 right-box">
            <div class="row align-items-center justify-content-center">
              <div class="header-text mb-4 justify-content-center text-center">
                <h2 style={{ color: "white", fontWeight: "800" }}>
                  OPERATOR SIGN UP
                </h2>
                <p>It's free, quick and easy</p>
              </div>
              {error && <Message variant="alert-danger">{error}</Message>}
              <form
                className="form-login3"
                onSubmit={submitHandler}
                style={{
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-lg bg-light fs-6 m-lg-2"
                    placeholder="Username"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div class="input-group mb-3">
                  <input
                    class="form-control form-control-lg bg-light fs-6 m-lg-2"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div class="input-group mb-3">
                  <input
                    type="password"
                    class="form-control form-control-lg bg-light fs-6 m-lg-2"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {/* <div class="input-group mb-3">
                  <input
                    type="password"
                    class="form-control form-control-lg bg-light fs-6 m-lg-2"
                    placeholder="Repeat Password"
                  />
                </div> */}

                <div class="input-group mb-2 d-flex justify-content-between">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="formCheck"
                    />
                    <label
                      for="formCheck"
                      class="form-check-label"
                    >
                      <small>Remember Me</small>
                    </label>
                  </div>
                  <div class="forgot">
                    <small>
                      <a href="#">Forgot Password?</a>
                    </small>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <button
                    class="btn btn-lg btn-primary w-100 fs-6"
                    type="submit"
                  >
                    Sign up
                  </button>
                </div>

                <div class="row">
                  <p class="d-flex justify-content-center align-items-center mt-2">
                    Have an account?{" "}
                    <a
                      href="./signin"
                      style={{ fontWeight: 600, marginLeft: "1rem" }}
                    >
                      Sign In
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        }
      </div>
      <Footer />
    </>
  );
};

export default SignUpScreen;
